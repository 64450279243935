import {initializeApi} from "@/api/base";
const api = initializeApi("/api/scount", {});
async function getOrganizers(pagination, sorting, search) {
  const response = await api
    .get(`/api/v1/organizers`, {
      params: {
        searchString: search,
        ...pagination,
        ...sorting,
      }
    })
    .then(response => {
      return {status: response.status, data: response.data};
    });

  return response;
}

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/scount", {'X-MarketingProgram-Id': marketingProgramId});

  // todo: debug host
  // const debugHost = 'http://localhost:5002';

  async function getLogging(pagination, sorting, searchString, dateFrom, dateTo, objectType) {
    const response = await api
      .get(`api/v1/keycloak-events`, { 
        params: {
          ...pagination,
          ...sorting,
          searchString: searchString,
          dateFrom: dateFrom,
          dateTo: dateTo,
          objectType
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
      return response;
  }

  async function getGoalGroup() {
    const response = await api
      .get(`/api/v1/goal-groups`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }
  async function createGoalGroup(model) {
    const response = await api
      .post(`/api/v1/goal-groups`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
    return response;
  }
  async function editGoalGroup(goalGroupId, model) {
    const response = await api
      .post(`/api/v1/goal-groups/${goalGroupId}`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
    return response;
  }
  async function deleteGoal(goalGroupId, goalId) {
    const response = await api
      .delete(`/api/v1/goal-groups/${goalGroupId}/goals/${goalId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function addGoal(goalGroupId, model) {
    const response = await api
      .post(`/api/v1/goal-groups/${goalGroupId}/goals`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
    return response;
  }

  async function editGoal(goalGroupId, goalId, model) {
    const response = await api
      .post(`/api/v1/goal-groups/${goalGroupId}/goals/${goalId}`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
    return response;
  }

  async function getMarketingProgram(marketingProgramId) {
    const response = await api
      .get(`/api/v1/marketing-programs/${marketingProgramId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function changeMarketingProgram(model) {
    const response = await api
      .patch(`/api/v1/marketing-programs/${marketingProgramId}`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
    return response;
  }

  async function uploadRegistrationIcon(marketingProgramId, file) {
    const formData = new FormData();
    formData.append("RegistrationIconFile", file, file.name.toLowerCase());

    const response = await api
      .post(`/api/v1/marketing-programs/${marketingProgramId}/registrationicon`, formData)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
    return response;
  }

  async function deleteRegistrationIcon(marketingProgramId) {
    const response = await api
      .delete(`/api/v1/marketing-programs/${marketingProgramId}/registrationicon`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
    return response;
  }

  async function authSportDataApplication() {
    const response = await api
      .post(`/api/v1/sportdata/auth`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getExerciseTemplates(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/exercise-templates`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getExerciseTemplate(exerciseTemplateId) {
    const response = await api
      .get(`/api/v1/exercise-templates/${exerciseTemplateId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function createExerciseTemplate(exerciseTemplateId, model) {
    const response = await api
      .post(`/api/v1/exercise-templates`, {exerciseTemplateId, ...model, marketingProgramId})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function changeExerciseTemplate(exerciseTemplateId, model) {
    const response = await api
      .patch(`/api/v1/exercise-templates/${exerciseTemplateId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function deleteExerciseTemplate(exerciseTemplateId) {
    const response = await api
      .delete(`/api/v1/exercise-templates/${exerciseTemplateId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }  

  async function getExerciseTemplateTasks(exerciseTemplateId) {
    const response = await api
      .get(`/api/v1/exercise-templates/${exerciseTemplateId}/tasks`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getExerciseTemplateTask(exerciseTemplateId, exerciseTaskId) {
    const response = await api
      .get(`/api/v1/exercise-templates/${exerciseTemplateId}/tasks/${exerciseTaskId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function createExerciseTemplateTask(exerciseTemplateId, exerciseTemplateTaskId, model) {
    const response = await api
      .post(`/api/v1/exercise-templates/${exerciseTemplateId}/tasks`, {exerciseTemplateTaskId, ...model})
      .then(response => {
        return {status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function changeExerciseTemplateTask(exerciseTemplateId, exerciseTaskId, model) {
    const response = await api
      .patch(`/api/v1/exercise-templates/${exerciseTemplateId}/tasks/${exerciseTaskId}`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      })      
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function deleteExerciseTemplateTask(exerciseTemplateId, exerciseTemplateTaskId) {
    const response = await api
      .delete(`/api/v1/exercise-templates/${exerciseTemplateId}/tasks/${exerciseTemplateTaskId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  } 

  async function uploadExerciseTemplateDesktopImage(exerciseTemplateId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/exercise-templates/${exerciseTemplateId}/images/desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function uploadExerciseTemplateMobileImage(exerciseTemplateId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/exercise-templates/${exerciseTemplateId}/images/mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function getExercises(pagination, sorting, search, state, excludeIfAssignedParticipantId) {
    const response = await api
      .get(`/api/v1/exercises`, {
        params: {
          state: state,
          excludeIfAssignedParticipantId: excludeIfAssignedParticipantId,
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getExercise(exerciseId) {
    const response = await api
      .get(`/api/v1/exercises/${exerciseId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function createExercise(exerciseId, model) {
    const response = await api
      .post(`/api/v1/exercises`, {exerciseId, ...model, marketingProgramId})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function createExerciseFromTemplate(exerciseId, model) {
    const response = await api
      .post(`/api/v1/exercises/from-template`, {exerciseId, ...model, marketingProgramId})
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function changeExercise(exerciseId, model) {
    const response = await api
      .patch(`/api/v1/exercises/${exerciseId}`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function uploadDesktopImage(exerciseId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/exercises/${exerciseId}/images/desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function uploadMobileImage(exerciseId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/exercises/${exerciseId}/images/mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function publishExercise(exerciseId) {
    const response = await api
      .put(`/api/v1/exercises/${exerciseId}/publish`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function unPublishExercise(exerciseId) {
    const response = await api
      .put(`/api/v1/exercises/${exerciseId}/unpublish`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getExerciseTasks(exerciseId) {
    const response = await api
      .get(`/api/v1/exercises/${exerciseId}/tasks`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getExerciseTask(exerciseId, exerciseTaskId) {
    const response = await api
      .get(`/api/v1/exercises/${exerciseId}/tasks/${exerciseTaskId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function createExerciseTask(exerciseId, exerciseTaskId, model) {
    const response = await api
      .post(`/api/v1/exercises/${exerciseId}/tasks`, {exerciseTaskId, ...model})
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function changeExerciseTask(exerciseId, exerciseTaskId, model) {
    const response = await api
      .patch(`/api/v1/exercises/${exerciseId}/tasks/${exerciseTaskId}`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getParticipants(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/participants`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getParticipant(participantId) {
    const response = await api
      .get(`/api/v1/participants/${participantId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function editParticipantQuestionnaire(participantQuestionnaireId, model) {
    const response = await api
      .patch(`/api/v1/participantquestionnaires/${participantQuestionnaireId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
  
    return response;
  }

  async function editParticipantBalance(participantId, model) {
    const response = await api
      .patch(`/api/v1/participants/${participantId}/balance`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function blockParticipant(participantId) {
    const response = await api
      .patch(`/api/v1/participants/${participantId}/block`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function unblockParticipant(participantId) {
    const response = await api
      .patch(`/api/v1/participants/${participantId}/unblock`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function addParticipantExercise(participantExerciseId, participantId, exerciseId) {
    const response = await api
      .post(`/api/v1/participant-exercises`, { participantExerciseId, participantId, exerciseId  })
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });
  
    return response;
  }

  async function getTeams(pagination, sorting, search, count) {
    const response = await api
      .get(`/api/v1/teams`, {
        params: {
          count: count,
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }
  
  async function getTeam(teamId) {
    const response = await api
      .get(`/api/v1/teams/${teamId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
  
    return response;
  }

  async function createTeam(teamId, model) {
    const response = await api
      .post(`/api/v1/teams`, {teamId, ...model, marketingProgramId})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updateTeam(teamId, model) {
    const response = await api
      .patch(`/api/v1/teams/${teamId}`, model)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }
  
  async function enableTeam(teamId) {
    const response = await api
      .put(`/api/v1/teams/${teamId}/enable`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function disableTeam(teamId) {
    const response = await api
      .put(`/api/v1/teams/${teamId}/disable`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function uploadTeamLogo(teamId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());

    const response = await api
        .patch(`/api/v1/teams/${teamId}/images/logo`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });

    return response;
  }

  async function getTeamParticipants(pagination, sorting, search, teamId) {
    const response = await api
      .get(`/api/v1/team-participants?teamId=${teamId}`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function deleteTeamParticipant(teamId, participantId) {
    const response = await api
      .delete(`/api/v1/team-participants/${teamId}/${participantId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getMarketingPrograms(pagination, sorting, search) {
    const response = await api
      .get(`/api/v1/marketing-programs`, {
        params: {
          searchString: search,
          state: 'Published',
          ...pagination,
          ...sorting,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function getQuestionnaireQuestions(questionnaireId) {
    const response = await api
      .get(`/api/v1/questionnaires/${questionnaireId}/questions`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function getAnalitics(pagination, sorting, search, dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getAnaliticsCsv(dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/csv`, {
        responseType: 'blob',
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }

  async function getNewParticipants(pagination, sorting, search, dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/new-participants`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getNewParticipantsCsv(dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/new-participants/csv`, {
        responseType: 'blob',
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }
  async function getActiveCore(pagination, sorting, search, dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/active-core`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getActiveCoreCsv(dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/active-core/csv`, {
        responseType: 'blob',
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });

    return response;
  }

  async function getAtivityAnalitics(pagination, sorting, search, dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/activity-analitics`, {
        params: {
          searchString: search,
          ...pagination,
          ...sorting,
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getAtivityAnaliticsCsv(dateFrom, dateTo) {
    const response = await api
      .get(`/api/v1/analitics/activity-analitics/csv`, {
        responseType: 'blob',
        params: {
          dateFrom: dateFrom,
          dateTo: dateTo,
        }
      })
      .then(response => {
        return {status: response.status, data: response.data, request: response.request};
      });
    return response;
  }

  async function cancelParticipantExercise(participantExerciseId) {
    const response = await api
      .post(`/api/v1/participant-exercises/${participantExerciseId}/cancel`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      });

    return response;
  }

  async function changeMarketingProgramCode(marketingProgramId, marketingProgramCodeId, model) {
    const response = await api
      .patch(`/api/v1/marketing-programs/${marketingProgramId}/codes/${marketingProgramCodeId}`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      });

    return response;
  }
  //----------Calendar----------//

  async function createCalendar () {
    const response = await api
      .post(`/api/v1/calendar`)
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      });
    return response;
  }

  async function getCalendar(calendarId) {
    try {
      const response = await api.get(`/api/v1/calendar/${calendarId}`);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }

  async function updateCalendar (calendarId, model) {
    try {
      const response = await api.patch(`/api/v1/calendar/${calendarId}`, model);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }

  async function uploadCalendarImages (calendarId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    const response = await api
        .patch(`/api/v1/calendar/${calendarId}/images`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function getCalendarMonths() {
    try {
      const response = await api.get(`/api/v1/calendar-month`);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }

  async function getCalendarMonth(calendarMonthId) {
    try {
      const response = await api.get(`/api/v1/calendar-month/${calendarMonthId}`);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }

  async function createCalendarMonth(model) {
    const response = await api
      .post(`/api/v1/calendar-month`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updateCalendarMonth(calendarMonthId, model) {
    try {
      const response = await api.patch(`/api/v1/calendar-month/${calendarMonthId}`, {...model});
      if (response.status >= 200 && response.status < 300) {
        return { success: true, data: response.data };
      } else {
        return { success: false, status: response.status, data: response.data };
      }
    } catch (error) {
      return { success: false, error };
    }
  }

  async function deleteCalendarMonth(calendarMonthId) {
    const response = await api
      .delete(`/api/v1/calendar-month/${calendarMonthId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });

    return response;
  }

  async function getCalendarMonthEvents(calendarMonthId) {
    try {
      const response = await api.get(`/api/v1/calendar-event/calendarMonth/${calendarMonthId}`);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }

  async function getCalendarEvent( calendarEventId) {
    try {
      const response = await api.get(`/api/v1/calendar-event/${calendarEventId}`);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }

  async function getCalendarEvents(calendarMonthId) {
    try {
      const response = await api.get(`/api/v1/calendar/${calendarMonthId}/events`);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }

  async function getCalendarEvents1 () {
    try {
      const response = await api.get(`/api/v1/calendar-event`);
      return { status: response.status, data: response.data };
    } catch (error) {
      console.error(error);
      return { status: 500, data: null };
    }
  }
   // async function getCalendarEvent(calendarMonthId, calendarEventId) {
  //   try {
  //     const response = await api.get(`/api/v1/calendar/${calendarMonthId}/events/${calendarEventId}`);
  //     return { status: response.status, data: response.data };
  //   } catch (error) {
  //     console.error(error);
  //     return { status: 500, data: null };
  //   }
  // }

  async function createCalendarEvent(calendarEventId, model) {
    const response = await api
      .post(`/api/v1/calendar-event/${calendarEventId}`, {...model})
      .then(response => {
        return {success: true, status: response.status, data: response.data};
      })
      .catch((error) => {
        return {success: false, error};
      });

    return response;
  }

  async function updateCalendarEvent( calendarEventId, model) {
    try {
      const response = await api.patch(`/api/v1/calendar-event/${calendarEventId}`, {...model});
      if (response.status >= 200 && response.status < 300) {
        return { success: true, data: response.data };
      } else {
        return { success: false, status: response.status, data: response.data };
      }
    } catch (error) {
      return { success: false, error };
    }
  }

  async function deleteCalendarEvent(calendarEventId) {
    const response = await api
      .delete(`/api/v1/calendar-event/${calendarEventId}`)
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function uploadAciveMonthImage(calendarMonthId, file1 = null, file2 = null) {
    const formData = new FormData();
    if (file1) {
        formData.append("PastMonthImageFile", file1, file1.name.toLowerCase());
    }
    if (file2) {
        formData.append("ActiveMonthImageFile", file2, file2.name.toLowerCase());
    }
    const response = await api
        .patch(`/api/v1/calendar-month/${calendarMonthId}/image`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
    return { success: false, error };
          });
      return response;
  }
  async function uploadImageCalendar(calendarId, file1 = null, file2 = null) {
    const formData = new FormData();
    if (file1) {
      formData.append("DesktopFile", file1, file1.name.toLowerCase());
    }
    if (file2) {
        formData.append("MobileFile", file2, file2.name.toLowerCase());
    }
    const response = await api
        .patch(`/api/v1/calendar/${calendarId}/images`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function uploadPastMonthImage(calendarMonthId, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    const response = await api
        .patch(`/api/v1/calendar/${calendarMonthId}/images/past`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function uploadCalendarLogo(calendarEventId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/calendar-event/${calendarEventId}/logo`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function deleteCalendarEventLogo( calendarEventId, imageKey) {
    const response = await api
      .delete(`/api/v1/calendar-event/${calendarEventId}/logo`,
      {
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
          imageKey
        }
      }
      )
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function deleteImagesCalendar( calendarId, imageType) {
    const response = await api
      .delete(`/api/v1/calendar/${calendarId}/images`, {
        params: {
          imageType
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }
  async function deleteImagesMounth( calendarMonthId, calendarMonthImageType) {
    const response = await api
      .delete(`/api/v1/calendar-month/${calendarMonthId}/image`, {
        params: {
          calendarMonthImageType
        }
      })
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  return {
    getMarketingProgram,
    authSportDataApplication,
    getExerciseTemplates,
    getExerciseTemplate,
    createExerciseTemplate,
    changeExerciseTemplate,
    deleteExerciseTemplate,
    uploadExerciseTemplateDesktopImage,
    uploadExerciseTemplateMobileImage,
    getExerciseTemplateTasks,
    getExerciseTemplateTask,
    createExerciseTemplateTask,
    changeExerciseTemplateTask,
    deleteExerciseTemplateTask,
    getExercises,
    getExercise,
    createExercise,
    createExerciseFromTemplate,
    changeExercise,
    uploadDesktopImage,
    uploadMobileImage,
    publishExercise,
    unPublishExercise,
    getExerciseTasks,
    getExerciseTask,
    createExerciseTask,
    changeExerciseTask,
    getParticipants,
    getParticipant,
    editParticipantQuestionnaire,
    editParticipantBalance,
    blockParticipant,
    unblockParticipant,
    addParticipantExercise,
    getTeams,
    getTeam,
    createTeam,
    updateTeam,
    enableTeam,
    disableTeam,
    getTeamParticipants,
    deleteTeamParticipant,
    uploadTeamLogo,
    getMarketingPrograms,
    getQuestionnaireQuestions,
    getAnalitics,
    getAnaliticsCsv,
    getNewParticipants,
    getNewParticipantsCsv,
    getActiveCore,
    getActiveCoreCsv,
    getAtivityAnalitics,
    getAtivityAnaliticsCsv,
    cancelParticipantExercise,
    getGoalGroup,
    createGoalGroup,
    addGoal,
    deleteGoal,
    editGoal,
    editGoalGroup,
    changeMarketingProgramCode,
    getLogging,
    getOrganizers,
    createCalendarMonth,
    createCalendar,
    getCalendar,
    uploadCalendarImages,
    updateCalendar,
    getCalendarMonth,
    getCalendarMonths,
    getCalendarMonthEvents,
    deleteCalendarMonth,
    updateCalendarMonth,
    uploadAciveMonthImage,
    uploadPastMonthImage,
    createCalendarEvent,
    getCalendarEvents,
    getCalendarEvents1,
    deleteCalendarEvent,
    updateCalendarEvent,
    getCalendarEvent,
    uploadCalendarLogo,
    deleteCalendarEventLogo,
    uploadImageCalendar,
    deleteImagesCalendar,
    deleteImagesMounth,
    changeMarketingProgram,
    uploadRegistrationIcon,
    deleteRegistrationIcon
  };
}
export default {
  withMarketingProgram,
  getOrganizers
};

<template>
  <div>
    <v-progress-linear
      v-if="!team"
      color="deep-purple"
      height="10"
      indeterminate
    ></v-progress-linear>

    <div v-if="team">
      <v-form ref="form" validation>
        <v-row>
          <v-col cols="auto">
            <h1 class="cmn__h1">{{team.name ? team.name : 'Нет названия'}}</h1>
            <div class="identifier">ID: <CCopy :content="teamId" tooltip="left" /></div>
          </v-col>
          <v-col class="d-flex pt-5">
            <div class="ml-auto">
              <v-btn v-if="!isEdit" @click="editTeam" class="mr-2">
                <v-icon class="mr-2" icon small>mdi-pencil</v-icon>
                Редактировать
              </v-btn>
              <v-btn v-else @click="saveTeam" class="mr-2">Сохранить</v-btn>
              <v-btn v-if="!isEdit && team.state === 'Enabled'" @click="disableTeam" class="mr-2">
                <v-icon icon>mdi-eye-off</v-icon>
              </v-btn>
              <v-btn v-if="!isEdit && team.state === 'Disabled'" @click="enableTeam" class="mr-2">
                <v-icon icon>mdi-eye</v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>

        <div class="cmn__hr"></div>

        <v-row>
          <v-col cols="6">
            <v-text-field
              label="Название команды"
              v-model="team.name"
              :rules="validation.name"
              hide-details="auto"
              class="mb-5"
              :disabled="!isEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              label="Место дислокации команды"
              v-model="team.location"
              hide-details="auto"
              class="mb-5"
              :disabled="!isEdit"
            ></v-text-field>
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="team.onlineClub"
              label="Онлайн клуб"
              :disabled="!isEdit"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Реферальная ссылка для предоставления владельцу команды"
              v-model="refLink"
              readonly
              hide-details="auto"
              class="mb-5"
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              label="Код для авторизации в мобильном приложении"
              v-model="team.code"
              readonly
              hide-details="auto"
              class="mb-5"
            >
              <template v-slot:append>
                <v-icon @click="editMarketingProgramCode">mdi-pencil</v-icon>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="3">
            <upload-images
              @changeFile="changeFileActiveMonth"
              @fileInputClick="clickMonth"
              :imageSelected="team.logo"
              width="241"
              height="241"
              accept=".jpeg, .jpg"
              :readonly="isEdit"
            />
            <!-- <ImageSelector
              :preview-url="team.logo"
              :read-only="!isEdit"
              :save-image-func="changeLogo"
              accept=".jpg"
              title="ПК (192x192)"
              :subTitle="logoImageSubTitle"
              @show-preview="showImagePreview()"
            ></ImageSelector> -->
          </v-col>
          <v-col cols="9">
            <v-row>
              <v-col cols="6">
                <v-text-field
                  label="Лимит участников"
                  v-model="team.limit"
                  :rules="validation.limit"
                  hide-details="auto"
                  class="mb-5"
                  :disabled="!isEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="team.leader"
                  label="ФИО руководителя"
                  :disabled="!isEdit"
                />
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="team.url"
                  label="Ссылка на соц.сети или сайт"
                  :disabled="!isEdit"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <!-- <v-textarea
              v-model="team.description"
              label="О команде"
              counter
              maxlength="3000"
              :disabled="!isEdit"
            /> -->
            <label>О команде</label>
            <vue-editor :class="{disabled:!isEdit}" v-model="team.description" :editorToolbar="customToolbar"></vue-editor>
          </v-col>
        </v-row>
        <TeamParticipantsView v-if="teamId" :teamId="teamId" :is-edit="isEdit" />
      </v-form>

      <MarketingProgramCodeEditorDialog
        v-if="marketingProgramCodeEditorDialogShow"
        :save-func="changeMarketingProgramCode"
        :object-id="teamId"
        :object="{code: team.code, attributes: [{key: 'TeamId', value: teamId}]}"
        @closed="marketingProgramCodeEditorDialogShow = false"
      />
    </div>

    <v-dialog v-model="dialog.opened" width="720">
      <v-card>
        <v-card-title class="headline">{{ dialog.title }}</v-card-title>
        <v-card-text v-html="dialog.text">
        </v-card-text>
        <v-card-actions>
          <v-btn @click="closeDialog">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import scountApiInitializer from '@/api/scountApi';
import TeamParticipantsView from './TeamParticipantsView.vue';
import MarketingProgramCodeEditorDialog from '../MarketingProgramCodeEditorDialog.vue';

import Moment from 'moment'
import { VueEditor } from "vue2-editor";
import uploadImages from '@/components/uploadImages.vue';

export default {
  name: 'TeamView',
  components: { TeamParticipantsView, MarketingProgramCodeEditorDialog, VueEditor, uploadImages },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    return { marketingProgramStore }
  },

  data: () => ({
    customToolbar: [
      ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
    ],
    team: null,
    isEdit: false,
    logoImageFile: undefined,
    logoImageSubTitle: undefined,
    dialog: {
      opened: false,
      title: undefined,
      text: undefined
    },
    validation: {
      title: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 64) || 'Не более 64 символов.',
      ],
      limit: [
        value => (!value || !isNaN(parseInt(value))) || 'Требуется целое число.',
      ],
    },
    loading: false,
    teamEditorDialogShow: false,
    marketingProgramCodeEditorDialogShow: false,
  }),

  computed: {
    teamId() {
      return this.$route.params.teamId;
    },

    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    scountApi() {
      if (!this.mp)
        return null;

      return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
    },

    refLink() {
      if (!this.team || !this.teamId)
        return '';

      const devSlag = location.host.indexOf('.rr-dev.') > -1 || location.host.indexOf('localhost') > -1 ? '.rr-dev' : '';

      // TODO: вынести в единое место
      const pairs = [
        { marketingProgramId: 'a6a2d18f-940d-4237-b99e-ec2d81281689', domain: `https://runningmr${devSlag}.ru/` },
        { marketingProgramId: '5696b784-5be8-4f76-8e5c-df084275c27d', domain: `https://mgoprof${devSlag}.ru/` },
        { marketingProgramId: 'bec0c390-4d39-44fb-86d7-fe65c3b89883', domain: `https://mneposilam${devSlag}.ru/` },
        { marketingProgramId: 'f94c9680-ad60-4636-b9ed-f32ae5282622', domain: devSlag ? `https://shag${devSlag}.ru/` : 'https://shag.scount.pro/' },
        { marketingProgramId: 'f3a88565-1657-4779-ae47-b3cc0287fc13', domain: devSlag ? `https://farmeco${devSlag}.ru/` : 'https://фармчемпионат.рф/' },
        { marketingProgramId: 'af0f9923-f448-413c-ba01-e09cebcb95f6', domain: devSlag ? `https://auchan${devSlag}.ru/` : 'https://auchan.scount.pro/' },
        { marketingProgramId: '37f15c6d-308f-4fb8-bc45-50a1634439b7', domain: devSlag ? `https://superliga78${devSlag}.ru/` : 'https://superliga78.scount.pro/' },
        { marketingProgramId: '42cce763-abc3-490e-a461-1568d354951d', domain: devSlag ? `https://expo${devSlag}.ru/` : 'https://expo.scount.pro/' },
        { marketingProgramId: 'dfeaffb5-56a3-4135-8345-3625d24c6b4c', domain: devSlag ? `https://rrexpo${devSlag}.ru/` : 'https://rrexpo.scount.pro/' },
        { marketingProgramId: '4d008edc-17d1-4669-8bd8-10221c98586a', domain: devSlag ? `https://severstal${devSlag}.ru/` : 'https://severstal.scount.pro/' },
        { marketingProgramId: '537acce1-f980-4cdf-b5fd-850a16be085b', domain: devSlag ? `https://demo${devSlag}.ru/` : 'https://demo.scount.pro/' },
        { marketingProgramId: 'bf698762-9d3d-4b93-acde-8bb534dc56cb', domain: devSlag ? `https://sitronics${devSlag}.ru/` : 'https://sitronics.scount.pro/' },
        { marketingProgramId: '28acc7f8-39fc-4e58-b10e-5eec6c4d3125', domain: devSlag ? `https://lemana${devSlag}.ru/` : 'https://lemanapro.scount.pro/' },
      ];

      const host = pairs.find(x => x.marketingProgramId === this.mp.marketingProgramId).domain;

      return `${host}?t=${btoa(this.teamId)}`;
    },
  },

  methods: {
    clickMonth(fileUploadId) {
        document.getElementById(fileUploadId).click();
      },
    changeFileActiveMonth(imageFile, file) {
      if(imageFile) {
        this.logoImageFile = file
      }
    },
    isDisabledPublish() {
      return !this.team || Moment(this.team.endDate, 'DD.MM.YYYY hh:mm').toDate() < new Date()
    },

    editTeam() {
      this.isEdit = true;
    },

    async enableTeam() {
      await this.scountApi.enableTeam(this.teamId);
      await this.getTeam();
    },

    async disableTeam() {
      await this.scountApi.disableTeam(this.teamId);
      await this.getTeam();
    },

    async getTeam() {
      const response = await this.scountApi.getTeam(this.teamId);
      this.team = response.data;
      if (this.team.startDate)
        this.team.startDate = Moment.utc(this.team.startDate).local().format('DD.MM.YYYY HH:mm');
      if (this.team.endDate)
        this.team.endDate = Moment.utc(this.team.endDate).local().format('DD.MM.YYYY HH:mm');
    },

    async saveTeam() {
      const valid = await this.$refs.form.validate();

      if (!valid || !this.teamId)
        return;

      await this.changeTeam();
      await this.getTeam();

      this.isEdit = false;
    },

    async changeTeam() {
      const request = {
        name: this.team.name,
        limit: this.team.limit,
        location: this.team.location,
        onlineClub: this.team.onlineClub,
        leader: this.team.leader,
        url: this.team.url,
        description: this.team.description,
        attributes: []
      };

      const response = await this.scountApi.updateTeam(this.teamId, request);
      if (response.success && this.logoImageFile) {
        const logoResp = await this.scountApi.uploadTeamLogo(this.teamId, this.logoImageFile);
        if (!logoResp.success) {
          this.showError('Ошибка при загрузке logo', this.getUploadAfterSaveText(logoResp));
        }
      }

      await this.getTeam();
    },
    
    changeLogo(file) {
      this.logoImageFile = file;
      this.logoImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },

    getUploadAfterSaveText(resp) {
        const e = resp.error;
        if (!e) return 'Произошла ошибка';
        if (e.status === 413) return 'Фото не было загружено. Превышен максимальный размер изображения';
        if (e.status === 400 && e.responseData && e.responseData.errors && e.responseData.errors.File) {
          const errFile = e.responseData.errors.File;
          const max = errFile.find(x => x.startsWith('Maximum '));
          if (max)
            return 'Фото не было загружено. Превышен максимальный размер изображения.';
        }

        return e.detail;
    },

    showError(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    hideError() {
      this.dialog.title = undefined;
      this.dialog.text = undefined;
      this.dialog.opened = false;
    },

    async publishTeam() {
      if (!this.teamId || !this.team) {
        this.openDialog('Невозможно опубликовать команду', 'Не определена команда');
        return;
      }

      const tasksResponse = await this.scountApi.getTeamTasks(this.teamId);
      if (!tasksResponse || !tasksResponse.data || !(tasksResponse.data.length > 0)) {
        this.openDialog('Невозможно опубликовать команду', 'Сначала необходимо добавить работы');
        return;
      }

      await this.scountApi.publishTeam(this.teamId);
      await this.getTeam();
    },

    editMarketingProgramCode() {
      this.marketingProgramCodeEditorDialogShow = true;
    },

    async changeMarketingProgramCode(model) {
      console.log('model', model);
      await this.scountApi.changeMarketingProgramCode(this.mp.marketingProgramId, this.teamId, model);
      await this.getTeam();
    },

    showImagePreview(type) {
      this.showPreview = true;
      this.showPreviewType = type;
    },

    async unpublishTeam() {
      if (!this.teamId || !this.team) {
        this.openDialog('Невозможно снять с публикации команду', 'Не определена команда');
        return;
      }

      await this.scountApi.unPublishTeam(this.teamId);
      await this.getTeam();
    },

    getComplexityClass(complexity) {
      if (complexity < 1.4)
        return '__low';
      if (complexity < 1.9)
        return '__medium';
      return '__high';
    },

    openDialog(title, text) {
      this.dialog.title = title;
      this.dialog.text = text;
      this.dialog.opened = true;
    },

    closeDialog() {
      this.dialog.opened = false;
    },
  },

  async mounted() {
    this.loading = true;
    try {
      await this.getTeam();
    } finally {
      this.loading = false;
    }
  },
}
</script>

<style lang="scss">
.complexity {
  display: inline-block;
  margin-left: 8px;
  padding: 0 4px;
  border-radius: 4px;
  &.__low {
    background-color: #93de64;
  }
  &.__medium {
    background-color: #ffd666;
  }
  &.__high {
    background-color: #fa4646;
    color: white;
  }
}
.js-logo {
  display: flex;
}
.js-logo > div:last-child {
  flex-grow: 1;
  margin-left: 20px;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'

import DashboardView from '@/views/DashboardView';

import ExercisesView from '@/views/exercises/ExercisesView';
import ExerciseView from '@/views/exercises/ExerciseView';
import ExerciseTaskView from '@/views/exercises/ExerciseTaskView';

import NominationsView from '@/views/nominations/NominationsView';
import NominationView from '@/views/nominations/NominationView';

import CommunitiesView from '@/views/communities/CommunitiesView';
import CommunityView from '@/views/communities/CommunityView';

import LoggingView from '@/views/logging/LoggingView';

import ParticipantsList from '@/views/participants/ParticipantsList';
import ParticipantView from '@/views/participants/ParticipantView';

import AnaliticsView from '@/views/analitics/AnaliticsView';
import SellView from '@/views/analitics/SellView';
import PeriodView from '@/views/analitics/PeriodView';
import MembersView from '@/views/analitics/MembersView';
import CoreView from '@/views/analitics/CoreView';
import TreningView from '@/views/analitics/TreningView';
import PromotionView from '@/views/analitics/PromotionView';

import SportSettingsView from '@/views/sportsettings/SportSettingsView';

import ExerciseTemplatesView from '@/views/exercise-templates/ExerciseTemplatesView';
import ExerciseTemplateView from '@/views/exercise-templates/ExerciseTemplateView';

import NominationTemplatesView from '@/views/nomination-templates/NominationTemplatesView';
import NominationTemplateView from '@/views/nomination-templates/NominationTemplateView';

import PartnersView from '@/views/partners/PartnersView';
import CategoriesView from '@/views/categories/CategoriesView';

import TeamsView from '@/views/teams/TeamsView';
import TeamView from '@/views/teams/TeamView';

import PromotionsView from '@/views/promotions/PromotionsView';
import PromotionEditView from '@/views/promotions/PromotionEditView';

import PromocodesView from '@/views/promocodes/PromocodesView';
import OnboardingView from '@/views/onboarding/OnboardingView';
import RulesView from '@/views/rules/RulesView';
import FAQView from '@/views/faq/FAQView';
import FAQItemView from '@/views/faq/FAQItemView';
import FAQQuestionItemView from '@/views/faq/FAQQuestionItemView';

import FAQTemplatesView from '@/views/faq-templates/FAQTemplatesView.vue';
import FAQTemplatesQestionsView from '@/views/faq-templates/FAQTemplatesQuestionsView.vue';
import FAQTemplatesAnswersView from '@/views/faq-templates/FAQTemplatesAnswersView.vue';

import GoalView from '@/views/goal/GoalView';
import AmbassadorsView from '@/views/ambassadors/AmbassadorsView';
import CalendarView from '@/views/calendar/CalendarView';
import BannersView from '@/views/banners/BannersView';
import GeneralSettingsView from '@/views/generalSettings/GeneralSettingsView';
import MarketSectionsView from '@/views/MarketSections/MarketSectionsView';

import IntroView from '@/views/decoration/IntroView';

Vue.use(VueRouter)

// export default new VueRouter({
//   mode: 'hash', // https://router.vuejs.org/api/#mode
//   linkActiveClass: 'active',
//   scrollBehavior: () => ({ y: 0 }),
//   routes: configRoutes()
// });

export default new VueRouter({
  mode: "history",
  routes: configRoutes()
});

function configRoutes() {
  return [
    {
      path: '/',
      name: 'dashboard',
      component: DashboardView,
      meta: {
        breadCrumb: {
          text: 'Running Mir',
        }
      },
    },
    {
      path: '/exercises',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Задания',
          to: { name: 'Exercises' }
        }
      },
      children: [
        {
          path: '',
          name: 'Exercises',
          component: ExercisesView,
        },
        {
          path: ':exerciseId',
          component: {
            render(c) { return c('router-view'); }
          },
          meta: {
            // breadCrumb: {
            //   text: 'Задания',
            //   to: { name: 'Exercises' }
            // }
          },
          children: [
            {
              path: '',
              name: 'Exercise',
              component: ExerciseView,
            },
            {
              path: 'tasks/:taskId',
              name: 'ExerciseTask',
              component: ExerciseTaskView,
            }
          ]
        }
        // {
        //   path: ':exerciseId',
        //   name: 'Exercise',
        //   component: ExerciseView,
        //   meta: {
        //     breadCrumb(route) {
        //       const exerciseId = route.params.exerciseId;
        //       return {
        //         text: exerciseId,
        //         to: { name: 'Exercise', params: { exerciseId: exerciseId } }
        //       };
        //     }
        //   }
        // }
      ]
    },

    {
      path: '/nominations',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Номинации',
          to: { name: 'Nominations' }
        }
      },
      children: [
        {
          path: '',
          name: 'Nominations',
          component: NominationsView,
        },
        {
          path: '/nomination/:nominationId?',
          component: {
            render(c) { return c('router-view'); }
          },
          meta: {
            // breadCrumb: {
            //   text: 'Номинации',
            //   to: { name: 'Nominations' }
            // }
          },
          children: [
            {
              path: '',
              name: 'Nomination',
              component: NominationView,
            }
          ]
        }
      ]
    },
    {
      path: '/logging',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Журнал действий',
          to: { name: 'Logging' }
        }
      },
      children: [
        {
          path: '',
          name: 'Logging',
          component: LoggingView,
        },
      ]
    },

    {
      path: '/communities',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Сообщества',
          to: { name: 'Communities' }
        }
      },
      children: [
        {
          path: '',
          name: 'Communities',
          component: CommunitiesView,
        },
        {
          path: ':communityId',
          component: {
            render(c) { return c('router-view'); }
          },
          meta: {
            // breadCrumb: {
            //   text: 'Задания',
            //   to: { name: 'Exercises' }
            // }
          },
          children: [
            {
              path: '',
              name: 'Community',
              component: CommunityView,
            }
          ]
        }
      ]
    },
    
    {
      path: '/participants',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Участники',
          to: { name: 'Participants' }
        }
      },
      children: [
        {
          path: '',
          name: 'Participants',
          component: ParticipantsList,
        },
        {
          path: ':participantId',
          component: {
            render(c) { return c('router-view'); }
          },
          meta: {
            // breadCrumb: {
            //   text: 'Задания',
            //   to: { name: 'Exercises' }
            // }
          },
          children: [
            {
              path: '',
              name: 'Participant',
              component: ParticipantView,
            }
          ]
        }
      ]
    },

    {
      path: '/analitics/',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Аналитика',
        }
      },
      children: [
        {
          path: '',
          name: 'Analitics',
          component: AnaliticsView,
        },
        {
          path: 'sell',
          name: 'Sell',
          component: SellView,
        },
        {
          path: 'period',
          name: 'Period',
          component: PeriodView,
        },
        {
          path: 'members',
          name: 'Members',
          component: MembersView,
        },
        {
          path: 'core',
          name: 'Core',
          component: CoreView,
        },
        {
          path: 'trening',
          name: 'Trening',
          component: TreningView,
        },
        {
          path: 'promotion',
          name: 'Promotion',
          component: PromotionView,
        }
      ]
    },

    {
      path: '/sport-settings',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Настройки судейской системы',
          to: { name: 'SportSettings' }
        }
      },
      children: [
        {
          path: '',
          name: 'SportSettings',
          component: SportSettingsView,
        }
      ]
    },
    
    {
      path: '/exercise-templates',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Каталог заданий',
          to: { name: 'ExerciseTemplates' }
        }
      },
      children: [
        {
          path: '',
          name: 'ExerciseTemplates',
          component: ExerciseTemplatesView,
        },
        {
          path: '/exercise-template/:exerciseTemplateId?',
          component: {
            render(c) { return c('router-view'); }
          },
          children: [
            {
              path: '',
              name: 'ExerciseTemplate',
              component: ExerciseTemplateView,
            }
          ]
        }
      ]
    },
    
    {
      path: '/nomination-templates',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Каталог номинаций',
          to: { name: 'NominationTemplates' }
        }
      },
      children: [
        {
          path: '',
          name: 'NominationTemplates',
          component: NominationTemplatesView,
        },
        {
          path: '/nomination-template/:nominationTemplateId?',
          component: {
            render(c) { return c('router-view'); }
          },
          children: [
            {
              path: '',
              name: 'NominationTemplate',
              component: NominationTemplateView,
            }
          ]
        }
      ]
    },
    
    {
      path: '/partners',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Каталог партнеров',
          to: { name: 'Partners' }
        }
      },
      children: [
        {
          path: '',
          name: 'Partners',
          component: PartnersView,
        }
      ]
    },
    
    {
      path: '/categories',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Каталог категорий',
          to: { name: 'Categories' }
        }
      },
      children: [
        {
          path: '',
          name: 'Categories',
          component: CategoriesView,
        }
      ]
    },
    
    {
      path: '/teams',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Команды',
          to: { name: 'Teams' }
        }
      },
      children: [
        {
          path: '',
          name: 'Teams',
          component: TeamsView,
        },
        {
          path: '/team/:teamId?',
          component: {
            render(c) { return c('router-view'); }
          },
          meta: {
            // breadCrumb: {
            //   text: 'Команды',
            //   to: { name: 'Teams' }
            // }
          },
          children: [
            {
              path: '',
              name: 'Team',
              component: TeamView,
            }
          ]
        }
      ]
    },
    {
      path: '/goal',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Общая цель',
          to: { name: 'Goal' }
        }
      },
      children: [
        {
          path: '',
          name: 'Goal',
          component: GoalView,
        },
      ]
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQView,
      meta: {
        breadCrumb: {
          text: 'FAQ',
        }
      }
    },
    {
      path: '/faq/:themeId?',
      name: 'FAQItemView',
      component: FAQItemView,
      meta: {
        breadCrumb: {
          text: 'FAQ',
        }
      }
    },
    {
      path: '/faq/:themeId/:questionId?',
      name: 'FAQQuestionItemView',
      component: FAQQuestionItemView,
      meta: {
        breadCrumb: {
          text: 'FAQ',
        }
      }
    },
    {
      path: '/faq-templates',
      name: 'FAQTemplates',
      component: FAQTemplatesView,
      meta: {
        breadCrumb: {
          text: 'Каталог FAQ',
        }
      }
    },
    {
      path: '/faq-templates-qestions/:themeId?',
      name: 'FAQTemplatesQestions',
      component: FAQTemplatesQestionsView,
      meta: {
        breadCrumb: {
          text: 'Каталог FAQ',
        }
      }
    },
    {
      path: '/faq-templates-answers/:themeId?/:questionId?',
      name: 'FAQTemplatesAnswers',
      component: FAQTemplatesAnswersView,
      meta: {
        breadCrumb: {
          text: 'Каталог FAQ',
        }
      }
    },
    {
      path: '/intro',
      name: 'Intro',
      component: IntroView,
      meta: {
        breadCrumb: {
          text: 'Оформление',
        }
      }
    },
    {
      path: '/promocodes',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Промокоды',
          to: { name: 'Promocodes' }
        }
      },
      children: [
        {
          path: '',
          name: 'Promocodes',
          component: PromocodesView,
        },
      ]
    },
    {
      path: '/onboarding',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Онбординг блок',
          to: { name: 'Onboarding' }
        }
      },
      children: [
        {
          path: '',
          name: 'Onboarding',
          component: OnboardingView,
        },
      ]
    },
    {
      path: '/rules',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Правила',
          to: { name: 'Rules' }
        }
      },
      children: [
        {
          path: '',
          name: 'Rules',
          component: RulesView,
        },
      ]
    },
    {
      path: '/promotions',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Поощрения',
          to: { name: 'Promotions' }
        }
      },
      children: [
        {
          path: '',
          name: 'Promotions',
          component: PromotionsView,
        },
        {
          path: '/promotionEdit/:promotionId?',
          name: 'PromotionEdit',
          component: PromotionEditView,
          // meta: {
          //     breadCrumb(route) {
          //     const promotionId = route.params.promotionId;
          //     return {
          //       text: promotionId,
          //       to: { name: 'PromotionEdit', params: { promotionId: promotionId } }
          //     };
          //   }
          // }
        }
      ]
    },
    {
      path: '/ambassadors',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Амбассадоры',
          to: { name: 'Ambassadors' }
        }
      },
      children: [
        {
          path: '',
          name: 'Ambassadors',
          component: AmbassadorsView,
        },
      ]
    },
    {
      path: '/calendar',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Календарь',
          to: { name: 'Calendar' }
        }
      },
      children: [
        {
          path: '',
          name: 'Calendar',
          component: CalendarView,
        },
      ]
    },
    {
      path: '/calendar',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Календарь',
          to: { name: 'Calendar' }
        }
      },
      children: [
        {
          path: '',
          name: 'Calendar',
          component: CalendarView,
        },
      ]
    },
    {
      path: '/banners',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Баннеры',
          to: { name: 'Banners' }
        }
      },
      children: [
        {
          path: '',
          name: 'Banners',
          component: BannersView,
        },
      ]
    },
    {
      path: '/general-settings',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Общие настройки',
          to: { name: 'generalSettings' }
        }
      },
      children: [
        {
          path: '',
          name: 'generalSettings',
          component: GeneralSettingsView,
        },
      ]
    },
    {
      path: '/market-sections',
      component: {
        render(c) { return c('router-view'); }
      },
      meta: {
        breadCrumb: {
          text: 'Разделы',
          to: { name: 'marketSections' }
        }
      },
      children: [
        {
          path: '',
          name: 'marketSections',
          component: MarketSectionsView,
        },
      ]
    },
  ];
}
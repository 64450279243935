<template>
  <div class="pa-6 pt-0">
    <v-row>
      <v-col cols="12">
        <div @click="goTopics" class="d-flex align-items-center cursor">
          <v-icon small left color="primary">mdi-arrow-left</v-icon>
          <span class="primary--text">Назад к темам</span>
        </div>
      </v-col>
      <v-col cols="12">
        <h2 class="mb-5">{{ topicTemplateTitle }}</h2>
      </v-col>
    </v-row>
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      sort-by="order"
      :sort-desc="false"
      search-title="Найти по названию"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row = "clickRow"
    >
      <template #actions>
        <v-btn large @click="openMainDialog">
          <v-icon left>mdi-plus-circle</v-icon>
          Создать вопрос
        </v-btn>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click.stop="openMainDialog(item)">mdi-pencil</v-icon>
        <v-icon class="mr-2" @click.stop="confirmDialogOpen(item)">mdi-trash-can-outline</v-icon>
      </template>
    </DataTable>
    <MainDialog
      :dialog="createQuestionsDialog"
      :title="faqQuestionTemplateId ? 'Редактировать вопрос' : 'Создать вопрос'"
      @close="createQuestionsDialog = false"
      @save="save"
      :disabledSave="!valid"
    >
      <v-form
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-text-field
          v-model="data.title"
          :rules="validation.title"
          label="Вопрос"
        />
        <v-text-field
          v-model="data.order"
          :rules="validation.order"
          label="Приоритет"
        />
      </v-form>
    </MainDialog>
    <ConfirmDialog
      :dialog="confirmDialog"
      @close="confirmDialog = false"
      @save="deleteFAQTopicTemplate"
      title="Вы уверены что хотите удалить вопрос?"
    >
    </ConfirmDialog>
   <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
import MainDialog from '@/components/mainDialog.vue';
import ConfirmDialog from './dialogs/ConfirmDialog.vue';
import {
  getFAQThemeTemplateQuestions,
  createFAQThemeTemplateQuestion,
  deleteFAQThemeTemplateQuestion,
  updateFAQThemeTemplateQuestion,
  getFAQThemeTemplateQuestionsId
} from '@/api/faqTemplateApi';
import { uuid } from 'vue-uuid';
  export default {
    name: 'FAQTemplatesQuestionsView',
    components: {
      MainDialog,
      ConfirmDialog
    },
    data() {
      return {
        data: {},
        createQuestionsDialog: false,
        confirmDialog: false,
        headers: [
          { text: 'Вопрос', value: 'title' },
          { text: 'Приоритет', value: 'order', align: 'center' },
          { text: 'Действия', value: 'actions', align: 'center', sortable: false },
        ],
        items: [],
        searchFields: [
          { key: 'name', label: 'Название' },
        ],
        valid: true,
        validation: {
          title: [
            v => !!v || 'Введите название темы',
          ],
          order: [
            v => !!v || 'Введите приоритет',
            v => v > 0 || 'Значение должно быть больше 0',
          ],
        },
        updating: false,
        total: 0,
        topicTemplateTitle: '',
        createData: {},
        faqTopicTemplateId: null,
        faqQuestionTemplateId: null
      }
    },
    methods: {
      clickRow(item) {
        console.log('clickRow', item);
        const id = item.faqQuestionTemplateId;
        if (!id) return;
        this.$router.push({ name: 'FAQTemplatesAnswers', params: { themeId: this.faqTopicTemplateId, questionId: id } });
      },
      resetValidation () {
        if(this.$refs.form) this.$refs.form.resetValidation()
      },
      openMainDialog(item) {
        this.faqQuestionTemplateId = null
        console.log('openMainDialog', item);
        this.resetValidation();
        const id = item ? item.faqQuestionTemplateId : null;
        if (id) {
          getFAQThemeTemplateQuestionsId(id)
            .then((response) => {
              this.data = response.data
              this.createQuestionsDialog = true
              this.faqQuestionTemplateId = this.data.faqQuestionTemplateId
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          this.data = {}
          this.createQuestionsDialog = true
        }
      },
      editQuestionDialog(item) {
        this.$router.push({ name: 'FAQTemplatesAnswers' })
        console.log(item)
      },
      confirmDialogOpen(item) {
        this.faqQuestionTemplateId = item.faqQuestionTemplateId
        this.confirmDialog = true
      },
      deleteFAQTopicTemplate() {
        deleteFAQThemeTemplateQuestion(this.faqQuestionTemplateId, this.faqTopicTemplateId)
          .then(() => {
            this.$notify({ type: 'success', text: 'Вопрос успешно удален' });
            this.$router.push('/faq-templates-qestions/' + this.faqTopicTemplateId);
            this.confirmDialog = false;
          })
          .catch((error) => {
            console.log(error)
          })
      },
      tableUpdated(pagination, sorting, search) {
        this.fetchFAQThemeQuestions(pagination, sorting, search)
      },
      save() {
        if (this.faqQuestionTemplateId){
          const payload = {...this.data, faqTopicTemplateId: this.faqTopicTemplateId}; updateFAQThemeTemplateQuestion(payload)
            .then(() => {
              this.$notify({ type: 'success', text: 'Вопрос успешно обновлен' });
              this.$router.push('/faq-templates-qestions/' + this.faqTopicTemplateId);
              this.createQuestionsDialog = false;
            })
            .catch((error) => {
              console.log(error)
            })
        } else {
          const payload = {
          ...this.data,
          faqQuestionTemplateId: uuid.v4(),
          faqTopicTemplateId: this.faqTopicTemplateId
        }
        createFAQThemeTemplateQuestion(payload)
          .then(() => {
            this.$notify({ type: 'success', text: 'Вопрос успешно создан' });
            this.$router.push('/faq-templates-qestions/' + this.faqTopicTemplateId);
            this.createQuestionsDialog = false;
          })
          .catch((error) => {
            console.log(error)
          })
        }
      },
      async fetchFAQThemeQuestions(pagination, sorting, search) {
        this.updating = true
        this.faqTopicTemplateId = this.$route.params.themeId
        await getFAQThemeTemplateQuestions(this.faqTopicTemplateId, pagination, sorting, search)
          .then((response) => {
            this.items = response.data.questionsTempaltes.values
            this.total = response.data.questionsTempaltes.total
            this.topicTemplateTitle = response.data.topicTemplateTitle
            this.updating = false
          })
          .catch((error) => {
            console.log(error)
            this.updating = false
          })
      },
      goTopics() {
        this.$router.push({ name: 'FAQTemplates' })
      }
    },
    mounted() {
      this.faqTopicTemplateId = this.$route.params.themeId
    }

  }
</script>

<style lang="scss" scoped>

</style>
// import Vue from 'vue'
// import Vuex from 'vuex'

// Vue.use(Vuex)

// export default new Vuex.Store({
//   state: {
//   },
//   getters: {
//   },
//   mutations: {
//   },
//   actions: {
//   },
//   modules: {
//   }
// })

import { defineStore } from 'pinia'
import scountApiInitializer from '@/api/scountApi';
import scountStepsApi from '@/api/scountStepsApi';

export const useMarketingProgramStore = defineStore('marketingProgramStore', {

  state: () => ({
    marketingProgramId: null,
    organizerId: null,
    title: null,
    state: null,
    createdOn: null,
    publishedOn: null,
    parameters: {},
    code: null,
    isClosed: false
  }),

  getters: {
    isInitialized: (state) => state.organizerId !== null ? true : false,
  },

  actions: {
    async setMarketingProgramId(marketingProgramId) {
      if (this.marketingProgramId === marketingProgramId)
        return;

      this.marketingProgramId = marketingProgramId;
      this.organizerId = null;
      this.title = null;
      this.state = null;
      this.createdOn = null;
      this.publishedOn = null;
      this.parameters = null;
      this.code = null;
      this.isClosed = false;
      this.reward = null;
      this.type = null;
      this.registrationSettings = null;
      // this.marketingProgram = null;

      var scountApi = scountApiInitializer.withMarketingProgram(marketingProgramId);
      var scountApi1 = scountStepsApi.marketingProgramSteps(marketingProgramId);
      var {data} = await scountApi.getMarketingProgram(marketingProgramId);
      let reward = await scountApi1.getProgramsReward(marketingProgramId);
      this.organizerId = data.organizerId;
      this.title = data.title;
      this.state = data.state;
      this.createdOn = data.createdOn;
      this.publishedOn = data.publishedOn;
      this.parameters = data.parameters;
      this.code = data.code;
      this.isClosed = data.isClosed;
      this.reward = reward.data ? reward.data.reward : null;
      this.type = data.type
      this.registrationSettings = data.registrationSettings
    },
  },
})
import {initializeApi} from "@/api/base";

function withMarketingProgram(marketingProgramId) {
  const api = initializeApi("/api/content", {'X-MarketingProgram-Id': marketingProgramId});

  async function getIntroSection() {
    const response = await api
      .get('/api/v1/intro-sections')
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function changeIntroSection(introSectionId, model) {
    const response = await api
      .put(`/api/v1/intro-sections/${introSectionId}`, model)
      .then(response => {
        return {status: response.status, data: response.data};
      });
    return response;
  }

  async function uploadTopSectionDesktopImage(introSectionId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/intro-sections/${introSectionId}/images/top-section-desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function uploadTopSectionMobileImage(introSectionId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/intro-sections/${introSectionId}/images/top-section-mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function uploadBottomSectionDesktopImage(introSectionId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/intro-sections/${introSectionId}/images/bottom-section-desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        })
    return response;
  }

  async function uploadBottomSectionMobileImage(introSectionId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/intro-sections/${introSectionId}/images/bottom-section-mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        });
    return response;
  }

  async function uploadAboutPageDesktopImage(introSectionId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/intro-sections/${introSectionId}/images/about-page-desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        })
    return response;
  }

  async function uploadAboutPageMobileImage(introSectionId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/intro-sections/${introSectionId}/images/about-page-mobile`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        })
    return response;
  }

  async function uploadAutorizationImage(introSectionId, ImageKey, file) {
    const formData = new FormData();
    formData.append("file", file, file.name.toLowerCase());
    formData.append("ImageKey", ImageKey);
    const response = await api
        .patch(`/api/v1/intro-sections/${introSectionId}/images/autorization-desktop`, formData)
        .then(response => {
            return { success: true, status: response.status, data: response.data };
        })
        .catch((error) => {
          return {success: false, error};
        })
    return response;
  }

  return {
    getIntroSection,
    changeIntroSection,
    uploadTopSectionDesktopImage,
    uploadTopSectionMobileImage,
    uploadBottomSectionDesktopImage,
    uploadBottomSectionMobileImage,
    uploadAboutPageDesktopImage,
    uploadAboutPageMobileImage,
    uploadAutorizationImage
  };
}
export default {
  withMarketingProgram,
};
<template>
  <v-breadcrumbs :items="breadCrumbs">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
        :to="item.to"
        :disabled="item.disabled"
        exact
      >
        <v-icon class="mr-2" v-if="item.icon" color="#fff">{{ item.icon }}</v-icon>
        {{ item.text }}
      </v-breadcrumbs-item>
    </template>
  </v-breadcrumbs>
</template>

<script>
export default {
  name: 'AppBreadcrumbs',

  computed: {
    breadCrumbs() {
      return this.$route.matched.filter(m => !!m.meta && !!m.meta.breadCrumb).map(m => {
        if (typeof m.meta.breadCrumb === 'function')
          return m.meta.breadCrumb.call(this, this.$route);
        else
          return m.meta.breadCrumb;
      });
    },
  },
}
</script>
<style>
.v-breadcrumbs__item{
  font-size: 20px;
  color: #fff !important;
}
</style>
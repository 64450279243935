export default {
  items: [
    // {
    //   title: 'Главная',
    //   path: "/",
    //   icon: 'icon-dashboard',
    // },
    {
      alias: 'participants',
      title: 'Участники',
      path: "/participants",
      icon: 'icon-people'
    },
    {
      alias: 'exercises',
      title: 'Задания',
      path: "/exercises",
      icon: 'icon-fact_check',
    },
    {
      alias: 'nominations',
      title: 'Номинации',
      path: "/nominations",
      icon: 'icon-military_tech',
    },
    {
      alias: 'teams',
      title: 'Команды',
      path: "/teams",
      icon: 'icon-groups',
    },
    {
      alias: 'logging',
      title: 'Журнал действий',
      path: "/logging",
      icon: 'mdi-table-of-contents',
    },
    // {
    //   title: 'FAQ',
    //   path: "#",
    //   icon: 'icon-quiz',
    // },
    // {
    //   title: 'Календарь',
    //   path: "#",
    //   icon: 'icon-event',
    // },
    // {
    //   title: 'Общая цель',
    //   path: "#",
    //   icon: 'icon-adjust',
    // },
    // {
    //   title: 'Контент SimpleRun',
    //   path: "#",
    //   icon: 'icon-v-icon',
    // },

    // {
    //   title: 'Аналитика',
    //   path: "/analitics",
    //   icon: 'icon-insert_chart',
    // },
  ],
  itemsList: [
    {
      alias: 'settings',
      title: 'Настройки',
      icon: 'mdi-cog',
      children: [
        {
          alias: 'faq',
          title: 'FAQ',
          path: "/faq",
        },
        {
          alias: 'sport-settings',
          title: 'Судейская система',
          path: "/sport-settings",
          // icon: 'icon-flaky',
        },
        {
          alias: 'goal',
          title: 'Общая цель',
          path: "/goal",
        },
        {
          alias: 'onboarding',
          title: 'Онбординг',
          path: "/onboarding",
          // icon: 'icon-v-icon',
        },
        {
          alias: 'rules',
          title: 'Правила',
          path: "/rules",
        },
        {
          alias: 'ambassadors',
          title: 'Амбассадоры',
          path: "/ambassadors",
        },
        {
          alias: 'communities',
          title: 'Сообщества',
          path: "/communities",
        },
        {
          alias: 'calendar',
          title: 'Календарь',
          path: "/calendar",
        },
        {
          alias: 'banners',
          title: 'Баннеры',
          path: "/banners",
        },
        {
          alias: 'general-settings',
          title: 'Общие настройки',
          path: "/general-settings",
        },
        {
          alias: 'decoration',
          title: 'Оформление',
          path: "/intro",
        },
      ]
    },
    {
      alias: 'analitics',
      title: 'Аналитика',
      icon: 'icon-insert_chart',
      children: [
        {
          title: 'Общая за период',
          path: "/analitics/period",
        },
        {
          title: 'Новые участники',
          path: "/analitics/members",
        },
        {
          title: 'Активное ядро',
          path: "/analitics/core",
        },
        {
          title: 'Тренировки',
          path: "/analitics/trening",
        },
        {
          alias: 'analitics-sell',
          title: 'Продажи',
          path: "/analitics/sell",
        },
        {
          alias: 'analitics-promotions',
          title: 'Поощрения',
          path: "/analitics/promotion",
        },
        // {
        //   title: 'Задания',
        //   path: "#",
        // },
      ]
    },
    {
      alias: 'catalogs',
      title: 'Общие каталоги',
      icon: 'icon-list_alt',
      children: [
        {
          alias: 'exercise-templates',
          title: 'Задания',
          path: "/exercise-templates",
        },
        {
          alias: 'nomination-templates',
          title: 'Номинации',
          path: "/nomination-templates",
        },
        {
          alias: 'partners',
          title: 'Партнеры',
          path: "/partners",
        },
        {
          alias: 'faq-templates',
          title: 'FAQ',
          path: "/faq-templates",
        },
        {
          alias: 'categories',
          title: 'Категории',
          path: "/categories",
        },
        {
          alias: 'market-sections',
          title: 'Разделы маркета',
          path: "/market-sections",
        },
        // {
        //   title: 'FAQ',
        //   path: "/faq",
        // },
        // {
        //   title: 'Simple Run',
        //   path: "#",
        // },
        {
          alias: 'promotions',
          title: 'Поощрения',
          path: "/promotions",
        },
        {
          alias: 'promocodes',
          title: 'Промокоды',
          path: "/promocodes",
        },
        // {
        //   title: 'Каталог SimpleRun',
        //   path: "#",
        // },
      ]
    }
  ],
  analytics: [

  ]
}

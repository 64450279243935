<template>
  <div>
    <v-progress-linear
      v-if="loader"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <div v-else>
      <v-row>
        <v-col cols="8">
          <div class="d-flex">
            <v-avatar class="mr-4" :color="logo ? logo : 'rgba(217, 217, 217, 1)'">
              <img :src="logo" alt="">
            </v-avatar>
            <div class="main__title">
              <v-card-text class="nominations__h1 text-truncate">
                {{name ? name : ''}}
              </v-card-text>
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex">
          <div class="ml-auto">
            <!-- <v-btn v-if="isEdit" @click="switchingIsEdit">
              <v-icon left>mdi-pencil</v-icon>
              редактировать
            </v-btn> -->
            <v-btn @click="save" :disabled="!valid">
              <v-icon left>mdi-content-save</v-icon>
              сохранить
            </v-btn>
            <v-btn disabled class="ml-2">
              <v-icon>mdi-play-circle</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-row class="mt-4">
          <v-col cols="4">
            <v-text-field
              v-model="newMarketingProgramId"
              label="ID Программы"
              disabled
              dense
              class="noborder"
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="organizerId"
              label="ID Организатора"
              disabled
              dense
              class="noborder"
            />
          </v-col>
          <v-col cols="4">
            <v-select
              v-model="typeMp"
              label="Тип МП"
              :items="typesMp"
              item-text="name"
              item-value="value"
              dense
              disabled
            />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="name"
              :rules="validation.name"
              label="Название МП (до 25 символов)"
              dense
            />
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="url"
              label="URL"
              dense
              disabled
              />
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="code"
              label="Код программы"
              dense
            />
          </v-col>
          <v-col cols="2">
            <v-checkbox
              v-model="isClosed"
              label="Закрытая МП"
              dense
              disabled
            />
          </v-col>
          <v-col cols="8">
            <v-textarea
              v-model="description"
              label="Краткое описание (до 60 символов)"
              :rules="validation.description"
              dense
              auto-grow
              rows="1"
            />
          </v-col>
          <v-col cols="4" class="mt-1">
            <v-text-field
              v-model="order"
              :rules="validation.order"
              label="Порядковый номер"
              dense
            />
          </v-col>
          <v-col>
            <upload-images
              @changeFile="changeIconMP"
              @fileInputClick="clickIconImage"
              :imageSelected="imageIconSelected"
              :deleteButton="imageIconSelected ? true : false"
              @fileDelete="deleteIconImage"
              width="128"
              height="128"
              title="Икона МП"
              accept=".png"
              :fullNameUpdate="false"
              imageSize="128х128"
            />
          </v-col>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
          <v-col cols="12">
            <h5 class="text-h5">Установки МП</h5>
          </v-col>
          <v-col cols="3">
            <v-text-field
              v-model="reward"
              :rules="validation.reward"
              label="Баллов за 1000 шагов"
              dense
            />
          </v-col>
        </v-row>
      </v-form>
    </div>
    <notifications :duration="8000" position="bottom right"/>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import marketingProgramSteps from '@/api/scountStepsApi';
import scountApiInitializer from '@/api/scountApi';
import uploadImages from '@/components/uploadImages.vue';

  export default {
    components: {uploadImages},
    data: () => ({
      loader: false,
      settings: {},
      name: '',
      reward: 2,
      description: '',
      order: 0,
      logo: null,
      newMarketingProgramId: null,
      organizerId: null,
      url: null,
      code: null,
      isClosed: false,
      isEdit: true,
      valid: false,
      typeMp: '',
      validation: {
        name: [
          value => !!value || 'Обязательное поле.',
          value => (value && value.length <= 25) || 'Не больше 25 символов.',
        ],
        description: [
          value => !value || (value.length <= 60) || 'Не больше 60 символов.'
        ],
        order: [
          value => value !== undefined && value !== null || 'Обязательное поле.',
          value => (value === 0 || (value && Number.isInteger(value * 1))) || 'Требуется целое число.',
        ],
        reward: [
          value => !!value || 'Обязательное поле.',
          value => (value && Number.isInteger(value * 1)) || 'Требуется целое число.',
          value => (value && value > 0 && value < 101) || 'Требуется целое число от 1 до 100.',
        ],
      },
      typesMp: [
        {name: 'Стандартная', value: 'Standart'},
        {name: 'Клуб', value: 'Club'}
      ],
      imageIconSelected: null,
      imageIconFile: null
    }),
    setup() {
      const marketingProgramStore = useMarketingProgramStore()
      return { marketingProgramStore }
    },
    methods: {
      marketingProgramData() {
        // this.$refs.form.resetValidation();
        this.name = this.settings ? this.settings.title : null,
        this.logo = this.settings ? this.settings.parameters?.Logo : null,
        this.url = this.settings ? this.settings.parameters?.Url : null,
        this.newMarketingProgramId = this.mp.marketingProgramId
        this.organizerId = this.settings ? this.settings?.organizerId : null,
        this.code = this.settings ? this.settings.code : null,
        this.isClosed = this.settings ? this.settings.isClosed : null,
        this.reward,
        this.typeMp = this.settings ? this.settings.type : null
        this.description = this.settings && this.settings.registrationSettings ? this.settings.registrationSettings.shortDescription : null
        this.order = this.settings && this.settings.registrationSettings ? this.settings.registrationSettings.order : 0
        this.imageIconSelected = this.settings && this.settings.registrationSettings ? this.settings.registrationSettings.registrationIconUrl : null
      },
      async fetchReward() {
        await this.marketingProgramSteps.getProgramsReward()
        .then((response) => {
          this.reward = response && response.data ? response.data?.reward : null
        })
      },
      async fetchSettings() {
        this.loader = true
        await this.scountApi.getMarketingProgram(this.mp.marketingProgramId)
        .then((response) => {
          if (response.status === 200) {
            this.settings = response.data
            this.marketingProgramData()
            this.loader = false
          }
        })
        .catch(() => {
          this.loader = false
        })
      },
      updateData() {
        this.fetchReward()
        this.fetchSettings()
      },
      async deleteIconImage() {
        await this.scountApi.deleteRegistrationIcon(this.mp.marketingProgramId)
        .then((response) => {
          if (response.success) {
            this.imageIconSelected = null
            this.$notify({type: 'success', text: 'Успешно удалено'});
            this.updateData()
          }
        })
      },
      changeReward () {
        this.marketingProgramSteps.changeProgramsReward({raward: this.reward})
      },
      async changeMarketingProgramCode() {
        const payload = {
          code: this.code,
          attributes: []
        }
        await this.scountApi.changeMarketingProgramCode(this.mp.marketingProgramId, this.mp.marketingProgramId, payload);
      },
      async save() {
        this.valid = await this.$refs.form.validate();
      if (!this.valid)
        return;
        const payload = {
          title: this.name,
          shortDescription: this.description,
          order: +this.order || 0,
        }
          await this.scountApi.changeMarketingProgram(payload)
            .then((response) => {
              if (response.success) {
                if(this.imageIconFile) {
                  this.scountApi.uploadRegistrationIcon(this.mp.marketingProgramId, this.imageIconFile)
                    .then(() => {
                      setTimeout(() => { this.updateData()}, 1000)
                    })
                }
                this.changeReward()
                this.changeMarketingProgramCode()
                this.$notify({type: 'success', text: 'Успешно сохранено'});
              } else {
                if(response.error.status === 422) {
                  this.$notify({type: 'error', text: 'Такой ордер уже существует' });
                } else {
                  this.$notify({type: 'error', text: `Ошибка: ${response.error.detail}` });
                }
              }
            }).finally(() => {
              this.loader = false
              setTimeout(() => { this.updateData()}, 1000)
            })
        },
      changeIconMP(imageFile, file) {
        if(imageFile) {
          this.imageIconSelected = imageFile
          this.imageIconFile = file
        }
      },
      clickIconImage(fileUploadId) {
        document.getElementById(fileUploadId).click();
      },
    },
    computed: {
      mp() {
        if (this.marketingProgramStore.isInitialized === false)
          return null;
        return this.marketingProgramStore;
      },
      marketingProgramSteps() {
        if (!this.mp)
          return null;
        return marketingProgramSteps.marketingProgramSteps(this.mp.marketingProgramId);
      },
      scountApi() {
        if (!this.mp)
          return null;
        return scountApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
      },
    },
    mounted() {
      this.updateData()
    }
  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="mt-6 px-4 pb-8 faq">
    <div @click="goQuestions" class="d-flex align-items-center cursor pb-6">
      <v-icon small left color="primary">mdi-arrow-left</v-icon>
      <span class="primary--text">Назад к вопросам</span>
    </div>
    <div class="d-flex justify-space-between align-items-center">
      <div class="d-flex align-items-center">
        <h2>{{ questionTitle }}</h2>
      </div>
      <div>
        <v-btn large class="mr-4" @click="saveAnswer">
          <v-icon left>mdi-content-save</v-icon>
          сохранить
        </v-btn>
        <v-btn :disabled="!answerParagraph" large @click="deleteAnswer">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <!-- <v-row class="mt-8">
      <v-col cols="6">
        <v-text-field
          v-model="questionTitle"
          label="Название вопроса"
          dense
        />
      </v-col>
    </v-row> -->
    <v-row>
      <v-col cols="6">
        <p class="answer__title">Ответ</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="pl-0">
        <vue-editor v-model="answerParagraph" :editorToolbar="customToolbar"></vue-editor>
      </v-col>
      <v-col cols="12">
        <v-alert
          border="top"
          colored-border
          type="info"
          color="rgb(77, 65, 246)"
          elevation="2"
          class="mt-4"
        >
        <div v-html="`{{supportEmai}} - email поддержки`">
        </div>
        <div v-html="`{{supportEmailText}} - текст email поддержки`">
        </div>
        <div v-html="`{{supportVK}} - ссылка на группу в VK`"></div>
        <div v-html="`{{supportReglament}} - ссылка на регламент`"></div>
        </v-alert>
      </v-col>
    </v-row>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import onboardingApiInitializer from '@/api/onboardingApi';
// import { uuid } from "vue-uuid";
import { VueEditor } from "vue2-editor";

    export default {
      components: {
        VueEditor
      },
      data: () => ({
        customToolbar: [
        ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
      ],
        // maxCharacters: 450, // Максимальное количество символов
        questionData: null,
        ansverData: null,
        paragraph: false,
        image: false,
        answerParagraph: '',
        themeId: '',
        questionId: '',
        files: '',
        selectedImage: null,
        imagePreview: null,
        previewImage: null,
        imageFile: null,
        ansverId: "",
        showPreview: false,
        questionTitle: '',
      }),
      setup() {
        const marketingProgramStore = useMarketingProgramStore()
        return { marketingProgramStore }
      },
      computed: {
        mp() {
          if (this.marketingProgramStore.isInitialized === false)
            return null;
          return this.marketingProgramStore;
        },
        onboardingApi() {
          if (!this.mp)
            return null;
          return onboardingApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
      },
      methods: {
        goQuestions() {
          const faqTopicId = this.$route.params.themeId
          this.$router.push({ name: 'FAQItemView', params: { themeId: faqTopicId } });
        },
        showImagePreview() {
        this.showPreview = true;
      },
        saveAnswer(){
          this.createdAnswer()
        },
        async createdAnswer() {
          // var faqThemeAnswerId = uuid.v4();
          const model = {
          faqQuestionId: this.$route.params.questionId,
          answer: this.answerParagraph,
          faqTopicId: this.themeId,
          }
          await this.onboardingApi
          .createFAQThemeQuestionAnswer(model)
          .then((response) => {
            if(response.success) {
              this.$notify({ type: 'success', text: 'Ответ успешно сохранен' });
            } else {
                this.$notify({ type: 'error', text: 'Ответ не должен быть пустым'});
              }
          })
          .catch((error) => {
            console.error('Error creating slide:', error);
          });
        },
        addParagraph() {
          this.paragraph = true
        },
        addImage() {
          this.image = true
        },
       async getFAQThemeQuestionAnswer() {
          const payload = {
            faqTopicId: this.themeId,
            faqQuestionId: this.questionId
          }
          this.themeId = this.$route.params.themeId
          this.questionId = this.$route.params.questionId
          const {data} = await this.onboardingApi.getFAQThemeQuestionAnswer(payload)
          this.ansverData = data
          // this.answerParagraph = data.values[0]?.paragraph
          // this.previewImage = data.values[0]?.imageLink
          // this.ansverId = data.values[0]?.id
        },
        async getFAQThemeQuestion() {
          this.themeId = this.$route.params.themeId
          this.questionId = this.$route.params.questionId
          const {data} = await this.onboardingApi.getFAQThemeQuestion(this.questionId)
          this.questionData = data
          console.log('222', this.questionData);
          this.answerParagraph = data.answer
          this.questionTitle = data.title
        },
        deleteAnswer() {
          const faqTopicId = this.$route.params.themeId
          const faqQuestionId = this.$route.params.questionId

          this.onboardingApi.deleteAnswer(faqQuestionId, faqTopicId)
        .then((response) => {
            if(response.success) {
              // this.$router.push({ name: 'FAQItemView'});

               this.getFAQThemeQuestion()

              this.$notify({type: 'success', text: 'Ответ успешно удален'})
            } else {
              this.$notify({ type: 'error', text: `Ответ не удален. Ошибка: ${response.error.detail}` });
            }
          })
          .catch((error) => {
            console.error('Error updating slide:', error);
          });
        }
        // uploadAnsverImage () {
          
        //   console.log(this.files);
        // }
      },
      async mounted() {
        await this.getFAQThemeQuestion()
        // await this.getFAQThemeQuestionAnswer()
      }
    }
</script>

<style lang="scss" scoped>
  .question__title{
    font-size: 34px;
    font-weight: 400;
  }
  .answer__title{
    font-size: 23px;
    padding: 20px 0;
    margin: 0;
  }
  .character-count {
  color: red; /* Цвет текста для счетчика символов */
}
</style>
<template>
  <div class="faq-templates pa-6">
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="order"
      :sort-desc="false"
      search-title="Найти тему"
      :search-fields="searchFields"
      :loading="updating"
      @update:table-options="tableUpdated"
      @click:row = "clickRow"
    >
      <template #actions>
        <div class="d-flex ">
          <v-btn large @click="openMainDialog">
            <v-icon left>mdi-plus-circle</v-icon>
            Создать тему
          </v-btn>
          <!-- <v-btn class="ml-3" large @click="attachDialogOpen">
            Прикрепить FAQ к МП
          </v-btn> -->
        </div>
      </template>
      <template v-slot:[`item.mp`]="{item}">
        <p class="accent--text font-weight-medium">{{ item.mp ? item.mp : '-' }}</p>
      </template>
      <template v-slot:[`item.actions`]="{item}">
        <v-icon class="mr-2" @click.stop = "openMainDialog(item)">mdi-pencil</v-icon>
        <v-icon class="mr-2" @click.stop = "confirmDialogOpen(item)">mdi-trash-can-outline</v-icon>
      </template>
    </DataTable>
    <ConfirmDialog
      :dialog="confirmDialog"
      @close="confirmDialog = false"
      @save="deleteFAQTopicTemplate"
      title="Вы действительно хотите удалить тему?"
    >
    </ConfirmDialog>
    <MainDialog
      :dialog="themeDialog"
      :title="themeItemId ? 'Редактирование темы' : 'Создание темы'"
      @close="themeDialog = false"
      @save="saveFAQThemeTemplate"
      :disabledSave="!valid"
    >
      <v-form
        v-model="valid"
        lazy-validation
        ref="form"
      >
        <v-text-field
          v-model="data.title"
          :rules="validation.title"
          label="Название темы"
        />
        <v-text-field
          v-model="data.order"
          :rules="validation.order"
          label="Порядок"
        />
      </v-form>
    </MainDialog>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>
<!-- toQuestions(item) -->
<script>
import ConfirmDialog from './dialogs/ConfirmDialog.vue';
import MainDialog from '@/components/mainDialog.vue';

import { uuid } from 'vue-uuid';
import {
  getMarketingPrograms,
  getFAQThemeTemplates,
  getFAQThemeTemplatesId,
  createFAQThemeTemplate,
  updateFAQThemeTemplate,
  deleteFAQThemeTemplate
} from '@/api/faqTemplateApi';

  export default {
    name: 'FAQTemplatesView',
    components: {
      ConfirmDialog,
      MainDialog
    },
    data() {
      return {
        data: {},
        updating: false,
        total: 0,
        themeDialog: false,
        attachDialog: false,
        createData: {},
        headers: [
          { value: 'title', text: 'Название темы', align: 'left', sortable: true },
          { value: 'questionsTemplateCount', text: 'Вопросов', align: 'center', width: 100, sortable: false },
          { value: 'order', text: 'Приоритет', align: 'center', width: 120, sortable: true },
          { value: 'mp', text: 'В каких МП', align: 'center', width: 100, sortable: false },
          { value: 'actions', text: 'Действия', align: 'center', width: 120, sortable: false },
        ],
        items: [],
        searchFields: [
          { key: 'title', label: 'Название темы' },
        ],
        validation: {
          title: [
            v => !!v || 'Заполните поле',
          ],
          order: [
            v => !!v || 'Заполните поле',
            v => v > 0 || 'Значение должно быть числом больше 0',
          ],
        },
      valid: true,
        marketingPrograms: [],
        confirmDialog: false,
        themeItemId: null
      }
    },
    methods: {
      resetValidation () {
        if(this.$refs.form) this.$refs.form.resetValidation()
      },
      clickRow(item) {
        const id = item.templateId;
        if (!id) return;
        this.$router.push(`/faq-templates-qestions/${id}`);
      },
      confirmDialogOpen(item) {
        this.themeItemId = item.templateId
        this.confirmDialog = true
      },
      async deleteFAQTopicTemplate() {
        await deleteFAQThemeTemplate(this.themeItemId)
        .then(() => {
          this.$notify({ type: 'success', text: 'Тема успешно удалена' });
          this.$router.push('/faq-templates');
          this.confirmDialog = false;
        })
      },
      async openMainDialog(item) {
        this.data = {};
        this.themeItemId = null
        this.resetValidation();
        const id = item ? item.templateId : null;
        if (id) {
          await getFAQThemeTemplatesId(id)
          .then(response => {
            this.data = response.data;
            this.themeItemId = response.data.faqTopicTemplateId
          });
        }
        this.themeDialog = true;
      },
      attachDialogOpen() {
        this.attachDialog = true;
      },
      toQuestions(item) {
        this.$router.push({ name: 'FAQTemplatesQestions', params: { themeId: item.id } });
      },
      async tableUpdated(pagination, sorting, search) {
        await this.fetchFAQThemeTemplates(pagination, sorting, search);
      },
      async fetchFAQThemeTemplates(pagination, sorting, search) {
        this.updating = true;
        try {
          const response = await getFAQThemeTemplates(
            pagination,
            sorting,
            search,
          );
          const {values, total} = response.data;
          this.total = total;
          this.items = values;
        } finally {
          this.updating = false;
        }
      },
      async saveFAQThemeTemplate() {
        if (this.themeItemId) {
          const payload = {...this.data, faqTopicTemplateId: this.themeItemId};
          await updateFAQThemeTemplate(payload)
          .then(() => {
            this.$notify({ type: 'success', text: 'Тема успешно обновлена' });
            this.$router.push('/faq-templates');
            this.themeDialog = false;
            this.themeItemId = null
          })
        } else {
          const payload = {
          faqTopicTemplateId: uuid.v4(),
          ...this.data
        };
        await createFAQThemeTemplate(payload)
        .then(() => {
          this.$notify({ type: 'success', text: 'Тема успешно создана' });
          this.$router.push('/faq-templates');
          this.themeDialog = false;
        })
        }
      },
      async getMarketingPrograms() {
        const response = await getMarketingPrograms();
        this.marketingPrograms = response.data.values;
      }
    },
    computed: {
    },
    mounted() {
      this.getMarketingPrograms();
    }
  }
</script>

<style lang="scss" scoped>

</style>
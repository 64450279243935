<template>
  <div class="pa-6 pt-0">
    <v-row>
      <v-col cols="12">
        <div @click="goQuestions" class="d-flex align-items-center cursor pb-6">
          <v-icon small left color="primary">mdi-arrow-left</v-icon>
          <span class="primary--text">Назад к вопросам</span>
        </div>
      </v-col>
    </v-row>
    <div class="d-flex justify-space-between align-items-center">
      <h2>{{ questionTitle }}</h2>
      <div class="d-flex">
        <v-btn @click="saveAnswer" :disabled="!valid || !answer" large>
          <v-icon left>mdi-content-save</v-icon>
          Сохранить
        </v-btn>
        <v-btn @click="confirmDialogOpen" :disabled="!answer" large class="ml-3">
          <v-icon>mdi-trash-can-outline</v-icon>
        </v-btn>
      </div>
    </div>
    <v-form ref="form" lazy-validation v-model="valid">
      <!-- <v-row class="mt-8">
        <v-col cols="8">
          <v-text-field
            v-model="questionTitle"
            :rules="validation.questionTitle"
            label="Название вопроса"
          ></v-text-field>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="8">
          <p class="text-h5 pb-6">Ответ</p>
        </v-col>
      </v-row>
      <v-col cols="12">
        <vue-editor v-model="answer" :editorToolbar="customToolbar"></vue-editor>
      </v-col>
      <v-col cols="12">
        <v-alert
          border="top"
          colored-border
          type="info"
          color="rgb(77, 65, 246)"
          elevation="2"
          class="mt-4"
        >
        <div v-html="`{{supportEmai}} - email поддержки`">
        </div>
        <div v-html="`{{supportEmailText}} - текст email поддержки`">
        </div>
        <div v-html="`{{supportVK}} - ссылка на группу в VK`"></div>
        <div v-html="`{{supportReglament}} - ссылка на регламент`"></div>
        </v-alert>
      </v-col>
    </v-form>

    <ConfirmDialog
      :dialog="confirmDialog"
      @close="confirmDialog = false"
      @save="deleteAnswer"
      title="Вы уверены что хотите удалить ответ?"
    >
    </ConfirmDialog>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
import ConfirmDialog from './dialogs/ConfirmDialog.vue';
import { VueEditor } from "vue2-editor";
import { getFAQThemeTemplateAnswers, getFAQThemeTemplateQuestionsId, updateFAQThemeTemplateAnswer, deleteFAQThemeTemplateAnswer } from '@/api/faqTemplateApi';
  export default {
    name: 'FAQTemplatesAnswersView',
    components: { ConfirmDialog, VueEditor },
    data() {
      return {
        answer: null,
        questionTitle: '',
        confirmDialog: false,
        valid: false,
        validation: {
          questionTitle: [
            v => !!v || 'Заполните поле',
          ],
          answer: [
            v => !!v || 'Заполните поле',
          ],
        },
        customToolbar: [
          ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
        ],
      }
    },
    methods: {
      resetValidation () {
        if(this.$refs.form) this.$refs.form.resetValidation()
      },
      confirmDialogOpen() {
        this.confirmDialog = true
      },
      fetchQuestion(questionId) {
        getFAQThemeTemplateQuestionsId(questionId)
        .then((response) => {
          this.questionTitle = response.data.title
        })
      },
      fetchAnswer(faqTopicTemplateId, faqQuestionTemplateId) {
        getFAQThemeTemplateAnswers(faqTopicTemplateId, faqQuestionTemplateId)
        .then((response) => {
          this.answer = response.data.answer
        })
      },
      saveAnswer() {
        if (this.$refs.form.validate()) {
          const faqTopicTemplateId = this.$route.params.themeId
          const faqQuestionTemplateId = this.$route.params.questionId
          updateFAQThemeTemplateAnswer(faqTopicTemplateId, faqQuestionTemplateId, this.answer)
          .then(() => {
            this.$notify({ type: 'success', text: 'Ответ успешно сохранен' });
          })
        }
      },
      deleteAnswer() {
        const faqTopicTemplateId = this.$route.params.themeId
        const faqQuestionTemplateId = this.$route.params.questionId
        deleteFAQThemeTemplateAnswer(faqQuestionTemplateId, faqTopicTemplateId)
        .then(() => {
          this.$notify({ type: 'success', text: 'Ответ успешно удален' });
          this.confirmDialog = false;
          const faqTopicTemplateId = this.$route.params.themeId
          const faqQuestionTemplateId = this.$route.params.questionId
          this.fetchAnswer(faqTopicTemplateId, faqQuestionTemplateId)
        })
      },
      goQuestions() {
        const faqTopicTemplateId = this.$route.params.themeId
        this.$router.push({ name: 'FAQTemplatesQestions', params: { themeId: faqTopicTemplateId } });
      }
    },
    mounted() {
      const faqTopicTemplateId = this.$route.params.themeId
      const faqQuestionTemplateId = this.$route.params.questionId
      this.fetchAnswer(faqTopicTemplateId, faqQuestionTemplateId)
      this.fetchQuestion(faqQuestionTemplateId)
      this.resetValidation()
    }
  }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="py-6 px-4">
    <v-row>
      <v-col>
        <v-btn large @click="openSlideDialog">
          <v-icon left>mdi-plus-circle</v-icon>
            Создать слайд
        </v-btn>
      </v-col>
    </v-row>    
    <DataTable
      controller="inRoute"
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="order"
      :sort-desc="true"
      :loading="updating"
      @update:table-options="tableUpdated"
    >
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon class="mr-2" @click="editSlideDialog(item)">mdi-pencil</v-icon>
      <v-icon class="mr-2" @click="confirmDialogOpen(item)" >mdi-trash-can-outline</v-icon>
    </template>
  </DataTable>
    <v-dialog
      v-model="slideDialog"
      max-width="720"
    >
      <v-card >
        <v-card-title class="modal__title text-h6 mb-8">
         {{ onboardingItemId ? 'Редактировать слайд' : 'Создать слайд' }}
        </v-card-title>
        <v-form 
          class="px-6"
          v-model="valid"
          lazy-validation
        >
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="slideData.title"
                :rules="validation.title"
                label="Заголовок слайда"
                dense
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="slideData.order"
                :rules="validation.order"
                label="Номер в слайдере"
                dense
              />
            </v-col>
            <v-col cols="12" class="mb-4">
              <!-- <v-textarea
                v-model="slideData.content"
                :rules="validation.content"
                label="Текст"
                dense
                counter="450"
              /> -->
              <vue-editor v-model="slideData.content"  :editorToolbar="customToolbar"></vue-editor>
            </v-col>
          </v-row>
        </v-form>
        <v-card-actions class="pb-4">
          <v-spacer></v-spacer>
          <v-btn
            @click="slideDialog = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="warning"
            @click="saveSlideDialog"
            :disabled ="!valid"
          >
            Сохранить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="confirmDialog"
      max-width="500"
    >
      <v-card>
        <v-card-title class="text-h6">
          Вы уверены что хотите удалить слайд?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            @click="confirmDialog = false"
          >
            Отменить
          </v-btn>

          <v-btn
            color="red"
            dark
            @click="deleteSlideItem"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <notifications :duration="5000" position="bottom right"/>
  </div>
</template>

<script>
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import onboardingApiInitializer from '@/api/onboardingApi';
  import { uuid } from "vue-uuid";
  import { VueEditor } from "vue2-editor";

    export default {
      name: 'OnboardingView',
      components: {
        VueEditor
      },
      setup() {
        const marketingProgramStore = useMarketingProgramStore()
        return { marketingProgramStore }
      },
      computed: {
        mp() {
          if (this.marketingProgramStore.isInitialized === false)
            return null;
          return this.marketingProgramStore;
        },
        onboardingApi() {
          if (!this.mp)
            return null;
          return onboardingApiInitializer.withMarketingProgram(this.mp.marketingProgramId);
        },
      },
      data: () => ({
        slideDialog: false,
        confirmDialog: false,
        updating: false,
        items: [],
        total: 0,
        headers: [
          { value: 'title', text: 'Название', align: 'start', sortable: true },
          { value: 'order', text: 'Номер', align: 'center', sortable: true, width: '100px' },
          { value: 'actions', text: 'Действия', align: 'center', sortable: false, width: '100px'  },
        ],
        slideData: {
          title: '',
          order: null,
          content: ''
        },
        customToolbar: [
          ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
        ],
        onboardingItemId: '',
        valid: true,
        validation: {
          title: [
            value => !!value || 'Обязательное поле.',
          ],
          order: [
            value => !!value || 'Обязательное поле.',
            value => !value || /^\d+(\.\d{1,2})?$/i.test(value) || 'Значение должно быть числом не меньше 0',
          ],
          content: [
            value => !!value || 'Обязательное поле.',
            value => (value && value.length <= 450) || 'Не более 450 символов.',
          ],
        },
      }),
      methods: {
        openSlideDialog () {
          this.onboardingItemId = null;
          this.slideDialog = true;
          this.slideData = {}
        },
        editSlideDialog (item) {
          this.slideDialog = true;
          this.slideData.title = item.title;
          this.slideData.order = item.order;
          this.slideData.content = item.content;
          this.onboardingItemId = item.id;
        },
        saveSlideDialog () {
          if (this.onboardingItemId) {
            this.updateSlide();
          } else {
            this.createSlide();
          }
        },
        createSlide() {
          var teamId = uuid.v4();
          const model = {
            title: this.slideData.title,
            order: +this.slideData.order,
            content: this.slideData.content,
            onboardingItemId: teamId
          };
          this.onboardingApi
            .createOnboarding(model)
            .then((response) => {
              if(response.success) {
                this.slideData = {};
                this.slideDialog = false;
                this.$notify({ type: 'success', text: 'Слайд успешно создан' });
                this.getOnboardingItems();
              } else {
                  this.$notify({ type: 'error', text: `Слайд не создан. Ошибка: ${response.error.detail}` });
                }
            })
            .catch((error) => {
              console.error('Error creating slide:', error);
            });
        },
        updateSlide() {
          const model = {
            title: this.slideData.title,
            order: +this.slideData.order,
            content: this.slideData.content,
          };
          this.onboardingApi
            .updateOnboarding(this.onboardingItemId, model)
            .then((response) => {
              if(response.success) {
                this.slideData = {};
                this.slideDialog = false;
                this.$notify({ type: 'success', text: 'Слайд успешно обновлен' });
                this.getOnboardingItems();
              } else {
                this.$notify({ type: 'error', text: `Слайд не обновлен. Ошибка: ${response.error.detail}` });
              }

            })
            .catch((error) => {
              console.error('Error updating slide:', error);
            });
        },
        confirmDialogOpen (item) {
          this.confirmDialog = true;
          this.onboardingItemId = item.id;
        },
        deleteSlideItem () {
          this.confirmDialog = false;
          this.onboardingApi.deleteOnboarding(this.onboardingItemId)
          .then((response) => {
              if(response.success) {
                this.$notify({type: 'success', text: 'Слайд успешно удален'})
                this.getOnboardingItems();
              } else {
                this.$notify({ type: 'error', text: `Слайд не удален. Ошибка: ${response.error.detail}` });
              }
           })
           .catch((error) => {
              console.error('Error updating slide:', error);
            });
        },
        async tableUpdated(pagination, sorting, search) {
          await this.getOnboardingItems(pagination, sorting, search);
        },
        async getOnboardingItems(pagination, sorting, search) {
          if (!this.onboardingApi) return;
          this.updating = true;
          try {
            const response = await this.onboardingApi.getOnboardingItems(
              pagination,
              sorting,
              search,
            );
            const {values, total} = response.data;
            this.total = total;
            this.items = values;
          } finally {
            this.updating = false;
          }
        },
      },
    }
</script>

<style lang="scss" scoped>
    .modal__title {
        background-color: #E0E0E0;
    }
</style>
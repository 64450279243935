<template>
  <div>
    <v-dialog
      :value="dialog"
      :width="widthDialog"
      persistent
      >
      <v-card>
        <div class="d-flex align-center grey lighten-2">
          <v-card-title class="headline">
            {{ title }}
          </v-card-title>
        </div>
        <div class="pa-6">
          <div class="pb-6">
            <slot/>
          </div>
          <div class="d-flex justify-end g-4">
            <v-btn @click="$emit('close')">Отмена</v-btn>
            <v-btn @click="$emit('save')" :disabled="disabledSave"  class="ml-2" color="accent">{{ buttonSave }}</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'MainDialog',
    data() {
      return {
      }
    },
    props: {
      dialog: Boolean,
      title: String,
      disabledSave: {
        type: Boolean,
        default: false
      },
      widthDialog: {
        type: String,
        default: '600'
      },
      buttonSave: {
        type: String,
        default: 'Сохранить'
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
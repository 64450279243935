<template>
  <div>
    <MainDialog :dialog="dialog" @close="$emit('close')" buttonSave="Удалить" @save="$emit('save')" :title="title">
      <slot/>
    </MainDialog>
  </div>
</template>

<script>
import MainDialog from '@/components/mainDialog.vue';
  export default {
    name: 'ConfirmDialog',
    components: { MainDialog },
    props: {
      dialog: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        default: 'Подтвердите действие'
      }
    }
  }
</script>

<style lang="scss" scoped>

</style>
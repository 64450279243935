<template>
  <div class="page">
    <div v-if="pageLoading" class="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
    <div v-else class="promotion">
      <v-form ref="form" lazy-validation v-model="valid">
        <v-row>
          <v-col class="d-flex align-center text-xl">
            <h2>
              {{!promotionTitle ? 'Создание нового поощрения' : promotionTitle}}
            </h2>
          </v-col>
          <v-col class="d-flex">
            <div class="ml-auto">
              <v-btn
                v-if="!isEdit"
                @click="editPromotion"
                class="mr-2"
              >
                Изменить
              </v-btn>
              <v-btn
                v-else
                @click="savePromotion"
                :disabled="!valid"
                class="mr-2"
              >
                Сохранить
              </v-btn>
              <!-- <v-btn v-if="promotionId" @click="openDeleteConfirm"><v-icon>mdi-delete</v-icon></v-btn> -->
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="6">
            <v-text-field
              v-model="formData.name"
              :rules="validation.name"
              label="Наименование"
              dense
              hide-details="auto"
              class="mb-5"
              :disabled="!isEdit"
            />
          </v-col>
          <v-col cols="6">
            <v-text-field
              v-model="formData.subTitle"
              :rules="validation.subTitle"
              label="Подзаголовок"
              dense
              hide-details="auto"
              class="mb-5"
              :disabled="!isEdit"
            />
          </v-col>
          <v-col cols="12">
            <div :class="showMessage || showMessage1  ? 'active' : ''">
              <v-row>
                <v-col>
                  <p class="editor__label" :class="descriptionLength1 ? 'active' : ''">Цитата</p>
                </v-col>
              </v-row>
              <div :class="{'disabled' : !isEdit}">
                <vue-editor
                  ref="editor"
                  :editorToolbar="customToolbar"
                  v-model="formData.description"
                  @input="descriptionLength"
                  :disabled="!isEdit"
                />
              </div>
              <p v-if="showMessage" class="description__error">Обязательное поле.</p>
              <p v-if="showMessage1" class="description__error">Максимальное количесто символов 1200</p>
            </div>
          </v-col>
          <v-col :cols="formData.type === 'Prize' ? '3' : '4'">
            <v-select
              label="Тип поощрения"
              dense
              v-model="formData.type"
              :rules="validation.type"
              :items="promotionTypeItems"
              :disabled="!isEdit"
              @change="changeType"
            />
          </v-col>
          <v-col :cols="formData.type === 'Prize' ? '3' : '4'" v-if="formData.type === 'Prize'">
            <v-text-field
              label="Дата розыгрыша"
              v-model="formData.raffleOn"
              :rules="validation.raffleOn"
              hide-details="auto"
              :disabled="!isEdit"
              dense
            />
          </v-col>
          <v-col :cols="formData.type === 'Prize' ? '3' : '4'">
            <v-text-field
              label="Срок действия поощрения"
              v-model="formData.expiresOn"
              :rules="validation.date"
              hide-details="auto"
              :disabled="!isEdit"
              dense
            />
          </v-col>
          <v-col :cols="formData.type === 'Prize' ? '3' : '4'">
            <v-select
              label="Статус"
              v-model="formData.state"
              :items="promotionStateItems"
              disabled
              dense
            />
          </v-col>
          <v-col cols="6" class="d-flex">
            <v-text-field
              label="Категория"
              dense
              v-model="selectedCategory"
              :rules="validation.category"
              @click="openDialogTreeCategory"
              readonly
              :disabled="!isEdit"
              class="mr-2"
            />
            <v-btn :disabled="!isEdit" @click="openDialogTreeCategory">Выбрать категорию</v-btn>
          </v-col>
          <v-col cols="6" class="d-flex">
            <v-text-field
              label="Партнер"
              dense
              v-model="selectedPartnerName"
              :rules="validation.partner"
              readonly
              :disabled="!isEdit"
              @click="dialogPartnerOpen"
              class="mr-2"
            />
            <v-btn :disabled="!isEdit" @click="dialogPartnerOpen">Выбрать партнера</v-btn>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Дата события"
              v-model="formData.eventDate"
              :rules="validation.raffleOn"
              hide-details="auto"
              :disabled="!isEdit"
              dense
            />
          </v-col>
          <v-col cols="6">
            <ImageSelector
              :preview-url="imagePreviwMobile"
              :read-only="!isEdit"
              :save-image-func="changeMobileImage"
              accept=".jpg"
              title="Изображение карточки (608x280)"
              :subTitle="mobileImageSubTitle"
              @show-preview="showImagePreview('mobile')"
            />
          </v-col>
          <v-col cols="6">
            <ImageSelector
              :preview-url="imagePreviwDesktop"
              :read-only="!isEdit"
              :save-image-func="changeDesktopImage"
              accept=".jpg"
              title="Подробное изображение (1181x545)"
              :subTitle="desktopImageSubTitle"
              @show-preview="showImagePreview('desktop')"
            />
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col>
            <v-btn class="mr-3" @click="openDialogTreeCategory">
              <v-icon left>mdi-plus</v-icon>Добавить Правило</v-btn>
          </v-col>
        </v-row> -->
        <div v-if="promotionId">
          <v-row>
            <v-col>
              <h3>Маркетинговые программы, в которых используется поощрение</h3>
            </v-col>
          </v-row>
          <DataTable
            :headers="headers"
            :items="items"
            search-title="Найти маркетинговую программу"
            :server-items-length="total"
            sort-by="expiresOn"
            :sort-desc="false"
            :search-fields="searchFields"
            @update:table-options="tableUpdated"
          >
            <template #actions>
              <v-btn class="ml-3" @click.stop="dialogMpList" :disabled="!isEdit">Добавить маркетинговую программу</v-btn>
            </template>
            <template v-slot:[`item.actions`]="{item}">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    class="mr-2"
                    icon
                    v-on="on"
                    @click="editMpp(item)"
                    :disabled="!isEdit"
                    >
                    mdi-pencil-outline
                  </v-icon>
                </template>
                <span>Редактировать</span>
              </v-tooltip>
              <v-icon
                v-if="item.state === 'Неактивна'"
                class="mr-2"
                icon
                color="red"
                @click="enabledMpp(item.marketingProgramPromotionId)"
                :disabled="!isEdit"
                >
                mdi-power
              </v-icon>
              <v-icon
                v-if="item.state !== 'Неактивна'"
                class="mr-2"
                icon
                color="green"
                @click="disabledMpp(item.marketingProgramPromotionId)"
                :disabled="!isEdit"
                >
                mdi-power
              </v-icon>
            </template>
          </DataTable>
        </div>
      </v-form>
      <v-dialog
        v-model="dialog"
        max-width="790"
      >
        <v-card class="pa-4">
          <v-card-title class="pa-0" >Выберите маркетинговую программу из списка</v-card-title>
          <v-data-table
            :headers="headersMpProgramms"
            :items="formattedMarketingPrograms"
            @click:row="selectMarketingProgram"
            class="mp__list"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialog2"
        max-width="790"
      >
        <v-card>
          <v-card-title class="pa-6 grey lighten-2">
            <v-col class="pa-0" cols="12">
              <span>Добавление поощрения в маркетинговую программу {{ mpName }}</span>
            </v-col>
            <v-col class="pa-0" cols="12">
              <span>Маркетинговая программа: </span> <span>{{ mpName }}</span>
            </v-col>
          </v-card-title>
          <v-form class="pa-4"  ref="form" v-model="valid" lazy-validation>
            <v-row>
              <v-col cols="6">
              <v-text-field
                v-model="formPromotionData.priceInPoints"
                :rules="validation.priceInPoints"
                label="Цена в баллах"
                hide-details="auto"
                class="mb-5"
              />
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="formPromotionData.limitOnParticipant"
                :rules="validation.limitOnParticipant"
                label="Лимит для участника"
                hide-details="auto"
                class="mb-5"
              />
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="formPromotionData.sort"
                :rules="validation.sort"
                label="Сортировка"
                hide-details="auto"
                class="mb-5"
              />
            </v-col>
            <v-col cols="12">
              <vue-editor
                :class="{disabled:!isEdit && this.action !== 'create'}"
                v-model="formPromotionData.howToGet"
                :editorToolbar="customToolbar"
                :disabled="!isEdit && this.action !== 'create'"
              />
            </v-col>
            <v-col class="d-flex justify-end" cols="12">
              <v-btn color="primary" :disabled="!valid || !isEdit" @click="saveMpp">Сохранить</v-btn>
            </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogCatalog"
        max-width="600"
      >
        <v-card class="pa-4">
          <v-card-title class="pa-0" >Выберите категорию из списка</v-card-title>
          <DataTable
            :headers="headersCategory"
            :items="categoryData"
            search-title="Найти категорию"
            :server-items-length="totalCategory"
            sort-by="expiresOn"
            :sort-desc="false"
            :search-fields="searchCategory"
            @click:row="selectCategory"
            @update:table-options="tableCategoryUpdated"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogPartner"
        max-width="600"
      >
        <v-card class="pa-4">
          <v-card-title class="pa-0" >Выберите партнера из списка</v-card-title>
          <DataTable
            :headers="headerPartner"
            :items="partnersData"
            search-title="Найти партнеров"
            :server-items-length="totalPartner"
            sort-by="expiresOn"
            :sort-desc="false"
            :search-fields="searchPartner"
            @click:row="selectPartner"
            @update:table-options="tablePartnerUpdated"
          />
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogTreeCategory"
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h6 pa-6 grey lighten-2" >Добавить категорию</v-card-title>
            <div class="tree">
              <div  v-for="item in categoriesTree" :key="item.categoryTreeNodeId
">
                <div class="tree__item">
                  <v-checkbox v-model="selected" :value="item.categoryTreeNodeId" @change="changeCheckbox(item)"></v-checkbox>
                  <div class="tree__item-title">{{ item.name }}</div>
                </div>
                <div v-for="child in item.children" :key="child.id">
                  <div class="tree__item child" >
                    <v-checkbox v-model="selected" :value="child.categoryTreeNodeId"></v-checkbox>
                    <div class="tree__item-title">{{ child.name }}</div>
                  </div>
                  <div class="tree__item subchild" v-for="child in child.children" :key="child.id">
                    <v-checkbox v-model="selected" :value="child.categoryTreeNodeId"></v-checkbox>
                    <div class="tree__item-title">{{ child.name }}</div>
                  </div>
                </div>
              </div>
            </div>
            <v-card-actions class="pt-8 pa-6">
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogTreeCategory = false"
          >
            Отмена
          </v-btn>
          <v-btn
            color="warning"
            @click="createPromotionCategoryTree"
          >
          Сохранить
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
      <notifications :duration="5000" position="bottom right" width="auto"/>
    </div>
  </div>
</template>

<script>
  import marketApiInitializer from '@/api/marketApiNoMp';
  import { mapGetters, mapActions } from "vuex";
  import moment from 'moment';
  import { uuid } from "vue-uuid";
  import { VueEditor } from "vue2-editor";
  import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
  import { useKeycloakStore } from '@/stores/keycloakStore';

export default {
  components: {
    VueEditor
  },
  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },
  data: () => ({
    showMessage: false,
    showMessage1: false,
    limitDscription: 1200,
    lengthDescrition: 0,
    isEdit: false,
    pageLoading: false,
    promotionTitle: null,
    newPromotionId: null,
    dialogTreeCategory: false,
    formData: {
      state: 'Disabled',
    },
    customToolbar: [
      ["bold",{ list: "ordered"}, {list: "bullet" }, "link"],
    ],
    selectedPartnerName: '',
    selectedPartnerNameId: '',
    selectedCategoryName: '',
    selectedCategoryId: '',
    imagePreviwDesktop: null,
    imagePreviwMobile: null,
    valid: true,
    action: '',
    editMode: false,
    isCreating: false,
    searchFields: [
      { key: 'title', label: 'Наименование' },
    ],
    searchCategory: [
      { key: 'name', label: 'Наименование' },
    ],
    searchPartner: [
      { key: 'name', label: 'Наименование' },
    ],
    showDatePicker: false,
    alert: false,
    dialog: false,
    dialog2: false,
    dialogCatalog: false,
    dialogPartner: false,
    selectedRow: null,
    loading: false,
    promotionTypeItems: [
      {text:'Скидка 100%', value: 'Discount'},
      {text:'Скидка < 100%', value: 'Promocode'},
      {text:'Розыгрыш', value: 'Prize'}
    ],
    promotionStateItems: [
      {text:'Неактивен', value: 'Disabled'},
      {text:'Активен', value: 'Enabled'},
    ],
    headers: [
      { value: 'marketingProgram', text: 'Наименование', align: 'start', sortable: false },
      { value: 'priceInPoints', text: 'Цена в баллах', align: 'center', sortable: false },
      { value: 'limitOnParticipant', text: 'Лимит', align: 'center', sortable: false },
      { value: 'promocodeQty', text: 'Количество промокодов', align: 'center', sortable: false },
      { value: 'state', text: 'Статус', align: 'center', sortable: false },
      { value: 'sort', text: 'Сортировка', align: 'center', sortable: false },
      { value: 'actions', text: 'Действия', align: 'end', sortable: false },
    ],
    headersCategory: [
    { value: 'name', text: 'Наименование', align: 'start', sortable: false },
    ],
    headerPartner: [
    { value: 'name', text: 'Наименование', align: 'start', sortable: false },
    ],
    headersMpProgramms: [
      { value: 'title', text: 'Наименование', align: 'start', sortable: false },
      { value: 'createdOn', text: 'Опубликована', align: 'start', sortable: false },
    ],
    formPromotionData: {
      promotionId: '',
      marketingProgramId: '',
      priceInPoints: 0,
      limitOnParticipant: 0,
      sort: 0,
      howToGet: '',
    },
    pagination: {
      page: 1,
      itemsPerPage: 10,
    },
    categoryData: [],
    partnersData: [],
    items:[],
    selectedTableRow: null,
    mobileImageFile: undefined,
    mobileImageSubTitle: undefined,
    desktopImageFile: undefined,
    desktopImageSubTitle: undefined,
    expiresOnFormatted: null,
    marketingProgramId: '',
    marketingProgramPromotionId: '',
    mpName: null,
    selected: [],
    categoriesTree: [],
    validation: {
      name: [
        value => !!value || 'Обязательное поле.',
        value => (value && value.length <= 256) || 'Не более 256 символов.',
      ],
      limitOnParticipant: [
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      priceInPoints: [
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      sort: [
        value => /^\d+(\.\d{1,2})?$/.test(value) || 'Значение должно быть числом не меньше 0',
      ],
      howToGet: [
        value => !!value || 'Обязательное поле.',
      ],
      subTitle: [
        value => !!value || 'Обязательное поле.',
      ],
      description: [
        value => !!value || 'Обязательное поле.',
      ],
      type: [
        value => !!value || 'Обязательное поле.',
      ],
      category: [
        value => !!value || 'Обязательное поле.',
      ],
      partner: [
        value => !!value || 'Обязательное поле.',
      ],
      expiresOn: [
        value => !!value || 'Обязательное поле.',
      ],
      image: [
        value => !!value || 'Обязательное поле.'
      ],
      date: [
        value => !!value || 'Обязательное поле.',
        value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
      ],
      raffleOn: [
        value => !value || /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d$/i.test(value) || 'Некорректная дата. Формат ДД.ММ.ГГГГ',
      ],
    },
  }),
  computed: {
    ...mapGetters("promotions", ["promotion", "categories", "partners", "marketingPrograms", "marketingProgramPromotions", "marketingProgramPromotion", "total", "totalCategory", "totalPartner", "responseStatus", "promotionId"]),

    isSelected() {
      return this.selected.includes(this.formData.categoryTreeNodeIds);
    },
    marketApi() {
      return marketApiInitializer
    },
    promotionId() {
    return this.$route.params.promotionId;
    },
    formattedExpiresOn() {
      if (this.promotion && this.promotion.expiresOn) {
        return moment.utc(this.promotion.expiresOn).format('DD.MM.YYYY');
      }
      return null;
    },
    formattedDate() {
      if (this.formData.expiresOn) {
        return moment(this.formData.expiresOn).format('DD.MM.YYYY');
      }
      return '';
    },
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },
    formattedMarketingPrograms() {
      if (this.marketingPrograms2) {
        return this.marketingPrograms2.map(program => ({
          ...program,
          createdOn: moment(program.createdOn).format('DD.MM.YYYY'),
        }));
      }
      return [];
    },
    descriptionLength1() {
      return this.lengthDescrition > 0 ? true : false;
    },
    marketingPrograms2() {
      if (this.kl.isAnyMp) {
        return this.mp ? [ { marketingProgramId: this.mp.marketingProgramId, title: this.mp.title }] : [];
      } else {
        return this.marketingPrograms;
      }
    },
    kl() {
      return this.keycloakStore;
    },
    // imageMobile() {
    //   if (this.action === "edit" || this.action === "copy") {
    //     return this.formData.imageMobile || (this.promotion?.images ? this.promotion.images.mobile : null);
    //   } else return null
    // },

    // imageDesktop() {
    //   if (this.action === "edit" || this.action === "copy") {
    //     return this.formData.imageDesktop || (this.promotion?.images ? this.promotion.images.desktop : null);
    //   } else return null
    // },
    selectedCategory() {
      const count = this.selected.length;
      const declension = this.getDeclension(count);
      return `Выбрано: ${count} ${declension}`;
    },
  },
  methods: {
    ...mapActions("promotions",
      ["getPromotion", "getCategories", "getPartners", "getMarketingPrograms", "getMarketingProgramPromotions", "getMarketingProgramPromotion", 'disableMarketingProgramPromotion', 'enableMarketingProgramPromotion', "createMarketingProgramPromotion", "createPromotion", "updatePromotion", "copyPromotion", "updateMarketingProgramPromotion", "uploadPromotionDesktopImage", "uploadPromotionMobileImage"]),
      getDeclension(number) {
        const cases = [2, 0, 1, 1, 1, 2];
        const titles = ["категория", "категории", "категорий"];
        const caseIndex = (number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5];
        return titles[caseIndex];
      },
      createPromotionCategoryTree() {
        const id = this.$route.params.promotionId;
        const payload = {
          categoryTreeNodeIds: this.selected
        };
        this.marketApi.createPromotionCategoryTree(id, payload)
          .then(() => {
            this.$notify({ type: 'success', text: 'Категории изменены' });
            this.dialogTreeCategory = false;
            this.marketApi.getPromotion({promotionId: id})
          })
      },
      changeCheckbox(item) {
      console.log('changeCheckbox', item);
    },
    openDialogTreeCategory() {
      this.dialogTreeCategory = true;
      this.getTreeCategory();
    },
    changeType() {
      const inputDate = this.formData.raffleOn;
      const isFormattedDate = /^\d{2}\.\d{2}\.\d{4}$/.test(inputDate);
      if (isFormattedDate) {
        this.formData.raffleOn = inputDate;
      } else {
        this.formData.raffleOn = moment(inputDate).format('DD.MM.YYYY');
      }
    },
    descriptionLength() {
      const quill = this.$refs.editor.quill
      this.lengthDescrition = quill.getLength()
      this.showMessage = this.formData.description.trim() === '';
      this.showMessage1 = this.lengthDescrition > this.limitDscription;
      if (this.lengthDescrition > this.limitDscription) {
        quill.deleteText(this.limitDscription, this.lengthDescrition);
      }
    },
    // Делает поля активными
    editPromotion() {
      this.isEdit = true;
    },
    // Функционал выбора категории
    dialogCategoryOpen() {
      this.dialogCatalog = true;
    },
    selectCategory(item) {
      this.formData.category = { id: item.categoryId, name: item.name };
      this.selectedCategoryName = this.formData.category ? this.formData.category.name : null;
      this.selectedCategoryId = this.formData.category ? this.formData.category.id : null;
      this.dialogCatalog = false;
    },
    // Функционал выбора партнера
    dialogPartnerOpen() {
      this.dialogPartner = true
    },
    selectPartner(item) {
      this.formData.partner = { id: item.partnerId, name: item.name };
      this.selectedPartnerName = this.formData.partner ? this.formData.partner.name : null;
      this.selectedPartnerId = this.formData.partner ? this.formData.partner.id : null;
      this.dialogPartner = false;
      console.log(this.selectedPartnerNameId);
    },
    // Обновление таблицы с категориями
    async tableCategoryUpdated(pagination, sorting, search) {
      await this.getCategories({ pagination, sorting, search })
      .then(()=>{
        this.categoryData = this.categories
      })
    },
    // Обновление таблицы с партнерами
    async tablePartnerUpdated(pagination, sorting, search) {
      await this.getPartners({ pagination, sorting, search, creatorId: this.kl.filterByCreatorId })
      .then(()=>{
        this.partnersData = this.partners
      })
    },
    async tableUpdated(pagination, sorting, search, promotionId) {
      this.updating = true;
      promotionId = this.$route.params.promotionId;
      if(promotionId) {
      await this.getMarketingProgramPromotions({ pagination, sorting, search, promotionId })
      .then(() => {
        this.items = this.marketingProgramPromotions;
        for (let i = 0; i < this.items.length; i += 1) {
        const value = this.items[i];
        if (value.state === "Created") {
          value.state = "Активна";
        } else if (value.state === "Disabled") {
          value.state = "Неактивна";
        } else {
          value.state = "-";
          continue;
        }
          value.marketingProgram = value.marketingProgram ? value.marketingProgram.title : null
        }
          this.updating = false;
      });
      }
    },
    updateExpiresOn() {
      this.formData.expiresOn = moment(this.expiresOnFormatted, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ');
    },
    async dialogMpList() {
      await this.getMarketingPrograms()
      this.dialog = true
    },
    onRowClick() {
      this.dialog = false
      this.dialog2 = true
    },
    disabledMpp(marketingProgramPromotionId) {
      this.disableMarketingProgramPromotion({ marketingProgramPromotionId: marketingProgramPromotionId }).then(() => this.tableUpdated());
    },
    enabledMpp(marketingProgramPromotionId) {
      this.enableMarketingProgramPromotion({ marketingProgramPromotionId: marketingProgramPromotionId }).then(() => this.tableUpdated());
    },
    selectMarketingProgram(item) {
      this.formPromotionData.priceInPoints = 0;
      this.formPromotionData.limitOnParticipant = 0;
      this.formPromotionData.sort = 0;
      this.formPromotionData.howToGet = '';
      if(item) {
        this.selectedTableRow = JSON.parse(JSON.stringify(item));
        this.dialog = false
        this.dialog2 = true
      }
    },
    toggleDatePicker() {
      this.showDatePicker = !this.showDatePicker;
    },
    changeDesktopImage(file) {
      this.desktopImageFile = file;
      this.desktopImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },
    changeMobileImage(file) {
      this.mobileImageFile = file;
      this.mobileImageSubTitle = `${file.name} (${file.size / 1024}KB)`;
    },
    showImagePreview(type) {
      this.showPreview = true;
      this.showPreviewType = type;
    },
  async saveMpp() {
    const promotionId = this.$route.params.promotionId;
    this.formPromotionData.promotionId = promotionId;
    const formData = {
      marketingProgramId: this.selectedTableRow.marketingProgramId,
      marketingProgramPromotionId: this.marketingProgramPromotionId,
      promotionId: this.formPromotionData.promotionId,
      priceInPoints: this.formPromotionData.priceInPoints,
      limitOnParticipant: this.formPromotionData.limitOnParticipant,
      sort: this.formPromotionData.sort,
      howToGet: this.formPromotionData.howToGet,
      expiresOn: this.formPromotionData.expiresOn,
      unitsOfValue: ""
    };
    if (this.marketingProgramPromotionId) {
      await this.updateMarketingProgramPromotion(formData);
      this.dialog2 = false;
      this.tableUpdated();
    } else {
      await this.createMarketingProgramPromotion(formData);
      this.dialog2 = false;
      this.tableUpdated();
    }
  },
  resetForm() {
    this.formPromotionData = {
      promotionId: '',
      marketingProgramId: '',
      priceInPoints: 0,
      limitOnParticipant: 0,
      sort: 0,
      howToGet: '',
      expiresOn: null,
      raffleOn: null
    };
  },

  addMpp() {
    this.selectedTableRow = null;
    this.marketingProgramPromotionId = null;
    this.mobileImageFile = undefined;
    this.desktopImageFile = undefined;
    this.editMode = false;
    this.resetForm();
    this.dialog2 = false;
    this.tableUpdated();
  },

  editMpp(item) {
    console.log(item);
    this.mpName = item.marketingProgram;
    this.marketingProgramPromotionId = item.marketingProgramPromotionId
    this.editMode = true;
    this.dialog2 = true;
    this.formPromotionData.priceInPoints = item.priceInPoints;
    this.formPromotionData.limitOnParticipant = item.limitOnParticipant;
    this.formPromotionData.sort = item.sort;
    this.formPromotionData.howToGet = item.howToGet;
    this.selectedTableRow = item;
  },

  formatDate(dateString) {
    const date = moment.utc(dateString, "DD.MM.YYYY");
    date.startOf('day')
    return date.format('YYYY-MM-DDTHH:mm:ss.SSSSSS+00:00');
  },
  async savePromotion() {
    const id = uuid.v4();

    const data = {
      promotionId: id,
      state: this.formData.state,
      name: this.formData.name,
      subTitle: this.formData.subTitle,
      description: this.formData.description,
      type: this.formData.type,
      expiresOn: moment(this.formData.expiresOn, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
      partnerId: this.selectedPartnerId,
      categoryId: this.selectedCategoryId,
      raffleOn: this.formData.raffleOn ? moment(this.formData.raffleOn, 'DD.MM.YYYY').format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ') : null,
      creatorId: this.kl.userId,
      eventDate: this.formData.eventDate ? this.formatDate(this.formData.eventDate) : null
    }
    this.valid = await this.$refs.form.validate();
    if(this.valid) {
      if (this.promotionId) {
      data.promotionId = this.promotionId,
      data.partnerId = this.selectedPartnerId,
      data.categoryId =  this.selectedCategoryId,
      await this.marketApi.updatePromotion(data)
        const resp = await this.marketApi.getPromotion({promotionId: this.promotionId});
        if(this.mobileImageFile) {
          this.uploadPromotionMobileImage({ promotionId: this.promotionId, file: this.mobileImageFile });
        }
        if(this.desktopImageFile) {
          this.uploadPromotionDesktopImage({ promotionId: this.promotionId, file: this.desktopImageFile });
        }
        this.promotionTitle = resp.data.name;
        this.$router.push({ name: 'Promotions' });
      }
      else {
        await this.marketApi.createPromotion(data)
        .then(async response => {
          if(response.status === 200) {
            this.$notify({ type: 'success', text: 'Поощрение успешно создано' });
          }
          if(this.mobileImageFile && response.data) {
            await this.uploadPromotionMobileImage({ promotionId: response.data, file: this.mobileImageFile })
            .then(response => {
              console.log(response);
              this.$notify({ type: 'success', text: 'Мобильная картина успешно загружена' });
            })
            .catch(error => {
              this.$notify({ type: 'error', text: error });

            })
          }
          if(this.desktopImageFile && response.data) {
            await this.uploadPromotionDesktopImage({ promotionId: response.data, file: this.desktopImageFile })
            .then(response => {
              console.log(response);
              this.$notify({ type: 'success', text: 'Десктопная картина успешно загружена' });
            })
            .catch(error => {
              this.$notify({ type: 'error', text: error });
            })
          }
          this.$router.push(`/promotionEdit/${response.data}`);
        })
        .catch(error => {
          this.$notify({ type: 'error', text: error });
        })
      }
    }
  },
  getTreeCategory() {
    this.marketApi.getCategoryTrees()
    .then(response => {
      this.categoriesTree = response.data
    })
  }
  },
  async mounted() {
    this.pageLoading = true;
    const id = this.$route.params.promotionId
    if(id) {
      this.pageLoading = false;
      await this.marketApi.getPromotion({promotionId: id})
    .then(response => {
      this.$notify({ type: 'success', text: 'Поощрение загружено' });
      const { data } = response;
      if(data) {
        this.formData = data,
        this.formData.expiresOn = moment(this.formData.expiresOn).format('DD.MM.YYYY'),
        this.formData.raffleOn = moment(this.formData.raffleOn).format('DD.MM.YYYY')
        this.formData.eventDate = moment(this.formData.eventDate).format('DD.MM.YYYY')
        this.promotionTitle = data.name,
        this.selectedCategoryName = data.category.name,
        this.selectedPartnerName = data.partner.name,
        this.selectedCategoryId = data.category.categoryId,
        this.selectedPartnerId = data.partner.partnerId,
        this.imagePreviwDesktop = data.images.desktop,
        this.imagePreviwMobile = data.images.mobile
        this.selected = data.categoryTreeNodeIds
      }
    })
    .catch(error => {
      console.error('Ошибка при выполнении запроса', error);
      this.$notify({ type: 'error', text: error });
    });
    } else this.pageLoading = false;
  },
}
</script>

<style lang="scss" >
.page {
  height: calc(100vh - 100px);
}
.promotion{
  position: relative;
}
.loading {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
  .mp__list.v-data-table tbody tr:hover {
  cursor: pointer;
}
.alert{
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 999;
}
.description {
    max-width: 211px;
    ::v-deep  p{
      margin: 0;
    }
    &__error {
      color: #ff5252;
      font-size: 12px;
    }
  }
  .label {
    margin: 0;
    padding: 4px 0 2px;
    color: rgba(0, 0, 0, 0.6);
    &.active {
      font-size: 12px;
    }
  }
  .tree {
    padding: 20px;
    &__item {
      display: flex;
      align-items: center;
      gap: 5px;
      height: 50px;
      &.child {
        padding-left: 20px;
      }
      &.subchild {
        padding-left: 40px;
      }
    }
  }
</style>
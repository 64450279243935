<template>
  <v-app v-if="ready">
    <MarketingProgramSelectorDialog v-if="!mp"/>

    <div v-else>
      <AppSidebar/>

      <!-- <v-system-bar app
        dark
        color="red lighten-2"
      >
        <v-spacer></v-spacer>
        <v-icon>mdi-wifi-strength-4</v-icon>
        <v-icon>mdi-signal-cellular-outline</v-icon>
        <v-icon>mdi-battery</v-icon>
        <span>12:30</span>
      </v-system-bar> -->

      <v-app-bar height="76" color="#4D41F6" elevation="2" app>
        <p v-if="pageTitle" class="text-h5 font-weight-bold white--text">{{ pageTitle }}</p>
        <AppBreadcrumbs/>
      </v-app-bar>

      <v-main>
        <v-container fluid>
          <router-view></router-view>
        </v-container>
      </v-main>
      <!-- <v-footer padless app>
        <v-col
          cols="12"
        >
          {{ new Date().getFullYear() }} — <strong>Vuetify</strong>
        </v-col>
      </v-footer> -->
    </div>
  </v-app>
</template>

<script>
import { useMarketingProgramStore } from '@/stores/marketingProgramStore';
import { useKeycloakStore } from '@/stores/keycloakStore';
import AppSidebar from '@/containers/AppSidebar.vue';
import AppBreadcrumbs from '@/containers/AppBreadcrumbs.vue';
import MarketingProgramSelectorDialog from '@/containers/MarketingProgramSelectorDialog.vue';
import Cookies from 'js-cookie'



export default {
  name: 'App',
  components: { AppSidebar, AppBreadcrumbs, MarketingProgramSelectorDialog },
  props: {
    keycloak: Object
  },

  setup() {
    const marketingProgramStore = useMarketingProgramStore()
    const keycloakStore = useKeycloakStore()
    return { marketingProgramStore, keycloakStore }
  },

  data() {
    return {
      ready: false,
    }
  },

  computed: {
    mp() {
      if (this.marketingProgramStore.isInitialized === false)
        return null;

      return this.marketingProgramStore;
    },

    kl() {
      return this.keycloakStore;
    },
    pageTitle() {
      return this.$route.meta.title;
    },

  },

  methods: {
    getMpIdByGroup(klGroup) {
      switch (klGroup) {
        case '/MP-Slp': return 'a6a2d18f-940d-4237-b99e-ec2d81281689';
        case '/MP-Mgo': return  '5696b784-5be8-4f76-8e5c-df084275c27d';
        case '/MP-Ldpr': return  'bec0c390-4d39-44fb-86d7-fe65c3b89883';
        case '/MP-Shag': return  'f94c9680-ad60-4636-b9ed-f32ae5282622';
        case '/MP-Farm': return  'f3a88565-1657-4779-ae47-b3cc0287fc13';
        case '/MP-Auchan': return  'af0f9923-f448-413c-ba01-e09cebcb95f6';
        case '/MP-Sl78': return  '37f15c6d-308f-4fb8-bc45-50a1634439b7';
        case '/MP-SportExpo': return  '42cce763-abc3-490e-a461-1568d354951d';
        case '/MP-RussiaRunningExpo': return  'dfeaffb5-56a3-4135-8345-3625d24c6b4c';
        case '/MP-Severstal': return  '4d008edc-17d1-4669-8bd8-10221c98586a';
        case '/MP-Demo': return  '537acce1-f980-4cdf-b5fd-850a16be085b';
        case '/MP-Sitronics': return  'bf698762-9d3d-4b93-acde-8bb534dc56cb';
        case '/MP-Lemana': return  '28acc7f8-39fc-4e58-b10e-5eec6c4d3125';
        default: return null;
      }
    },
    getToken() {
      console.log('token', this.kl.keycloak);
      console.log('kl', this.kl);
      if (this.kl.keycloak && this.kl.keycloak.token) {
        const token = this.kl.keycloak.token;
        Cookies.set('token', token);
      }
    }
  },

  async mounted() {
    await this.keycloakStore.setKeycloak(this.$props.keycloak);

    const profile = await this.$props.keycloak.loadUserProfile();
    await this.keycloakStore.setProfile(profile)
    .then(() => {
      this.getToken()
    })

    const userInfo = await this.$props.keycloak.loadUserInfo();
    await this.keycloakStore.setUserInfo(userInfo);

    if (this.keycloakStore.isSimpleMp) {
      const mpId = this.getMpIdByGroup(this.keycloakStore.groups[0]);
      this.marketingProgramStore.setMarketingProgramId(mpId);
    }

    this.ready = true;
  }
}
</script>

<style lang="scss">
</style>

<template>
  <EditorDialog
    :title="'Добавить тему из каталога'"
    :max-width="720"
    :save-func="save"
    @closed="close"
  >
    <DataTable
      :headers="headers"
      :items="items"
      :server-items-length="total"
      sort-by="order"
      :sort-desc="true"
      search-title="Название каталога"
      :search-fields="searchFields"
      :loading="updating"
      :items-per-page="6"
      @update:table-options="tableUpdated"
    >
      <template v-slot:[`item.title`]="{item}">
        <v-checkbox class="mt-0 mb-1" v-model="item.check" :label="item.title" hide-details="true"
        ></v-checkbox>
      </template>
    </DataTable>
  </EditorDialog>
</template>

<script>
import editorDialogMixin from '@/global-components/dialog/mixins';
import { getFAQThemeTemplates } from '@/api/faqTemplateApi';


export default {
  name: 'ExerciseFromTempalteDialog',
  mixins: [editorDialogMixin],

  props: {
    object: {
      type: Object,
      default: () => {
        return {
          items: undefined
        }
      }
    },
    saveFunc: Function,
  },

  data: () => ({
    headers: [
      { value: 'title', text: 'Название', align: 'start', sortable: true },
      { value: 'questionsTemplateCount', text: 'Вопросов', align: 'start', sortable: false },
    ],
    items: [],
    total: 0,
    searchFields: [
      { key: 'title', label: 'Название' },
    ],
    updating: false,
  }),

  methods: {
    async tableUpdated(pagination, sorting, search) {
      await this.getExerciseTemplates(pagination, sorting, search);
    },

    async getExerciseTemplates(pagination, sorting, search) {
      // if (!this.scountApi) return;

      this.updating = true;

      try {
        const response = await getFAQThemeTemplates(
          pagination,
          sorting,
          search,
        );

        const {values, total} = response.data;

        this.total = total;
        this.items = values;
      } finally {
        this.updating = false;
      }
    },

    save() {
      this.saveFunc(this.items.filter(x => x.check).map(x => x.templateId));
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../../assets/global';
</style>
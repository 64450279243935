import {initializeApi} from "@/api/base";
const api = initializeApi("/api/content", {});
const apiMarket = initializeApi("/api/market", {});

async function getFAQThemeTemplates(pagination, sorting, search) {
  return api.get('/api/v1/faq-templates/topics', {
    params: {
      searchString: search,
      ...pagination,
      ...sorting,
    },
  });
}
async function getFAQThemeTemplatesId(faqTopicTemplateId) {
  return api.get(`/api/v1/faq-templates/topics/${faqTopicTemplateId}`);
}

async function createFAQThemeTemplate(data) {
  return api.post('/api/v1/faq-templates/topics', data);
}

async function updateFAQThemeTemplate( data) {
  return api.patch('/api/v1/faq-templates/topics', data);
}

async function deleteFAQThemeTemplate(topicTemplateId) {
  return api.delete(`/api/v1/faq-templates/topics/${topicTemplateId}`);
}

//Questions
async function getFAQThemeTemplateQuestions(faqTopicTemplateId, pagination, sorting, search) {
  return api.get(`/api/v1/faq-template/questions`, {
    params: {
      faqTopicTemplateId,
      searchString: search,
      ...pagination,
      ...sorting,
    },
  });
}

async function getFAQThemeTemplateQuestionsId(faqQuestionTemplateId) {
  return api.get(`/api/v1/faq-template/questions/${faqQuestionTemplateId}`);
}

async function createFAQThemeTemplateQuestion(data) {
  return api.post('/api/v1/faq-template/questions', data);
}

async function updateFAQThemeTemplateQuestion(data) {
  return api.patch('/api/v1/faq-template/questions', data);
}

async function deleteFAQThemeTemplateQuestion(faqQuestionTemplateId, faqTopicTemplateId) {
  return api.delete(`/api/v1/faq-template/questions/${faqQuestionTemplateId}/faqTopicTemplateId/${faqTopicTemplateId}`);
}

//Answers
async function getFAQThemeTemplateAnswers(faqTopicTemplateId, faqQuestionTemplateId) {
  return api.get(`/api/v1/faq-template/questions/answer`, {
    params: {
      faqTopicTemplateId,
      faqQuestionTemplateId
    },
  });
}

// async function getFAQThemeTemplateAnswersId(themeTemplateId, themeQuestionTemplateId, answerTemplateId) {
//   return api.get(`/api/v1/faq-templates/answers/${themeTemplateId}/questions/${themeQuestionTemplateId}/answers/${answerTemplateId}`);
// }

// async function createFAQThemeTemplateAnswer(data) {
//   return api.patch('/api/v1/faq-template/answers', data);
// }

async function updateFAQThemeTemplateAnswer(faqTopicTemplateId, faqQuestionTemplateId, answer) {
  return api.patch('/api/v1/faq-template/questions/answer', {
    faqTopicTemplateId,
    faqQuestionTemplateId,
    answer
  });
}

async function deleteFAQThemeTemplateAnswer(faqQuestionTemplateId, faqTopicTemplateId) {
  return api.delete(`/api/v1/faq-template/questions/${faqQuestionTemplateId}/topics/${faqTopicTemplateId}/answer`);
}

async function getMarketingPrograms(pagination, sorting, search) {
  return apiMarket.get('/api/v1/marketing-programs', {
    params: {
      searchString: search,
      ...pagination,
      ...sorting,
    },
  });
}

export {
  getMarketingPrograms,
  getFAQThemeTemplates,
  getFAQThemeTemplatesId,
  createFAQThemeTemplate,
  updateFAQThemeTemplate,
  deleteFAQThemeTemplate,
  getFAQThemeTemplateQuestions,
  getFAQThemeTemplateQuestionsId,
  createFAQThemeTemplateQuestion,
  updateFAQThemeTemplateQuestion,
  deleteFAQThemeTemplateQuestion,
  getFAQThemeTemplateAnswers,
  // getFAQThemeTemplateAnswersId,
  // createFAQThemeTemplateAnswer,
  updateFAQThemeTemplateAnswer,
  deleteFAQThemeTemplateAnswer
};